/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import deps from 'dependencies';
import op, { get }  from 'object-path';
import { Redirect } from 'react-router';
import EventsLink from 'shared/general/EventsLink';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormEmail, FormPassword } from 'shared/forms/elements/FormFields';
import { writeErrors } from 'shared/forms/elements/Utils';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import { isEmpty } from 'lodash';
import { getApplicationPhase, getHomePage } from 'shared/general/Util';
import { getQuerystringValues } from 'appdir/components/general/Util';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { checkJwtStatus } from '../../data/UserData/Util';
import Button from 'shared/general/Button';


/**
 * -----------------------------------------------------------------------------
 * React Component: LogInForm
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...props,
		Router: state['Router'],
		userData: op.get(state['UserData'], 'userData', {}),
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		applicationConfig: op.get(state['Config'], 'application'),
		configStatus: state['Config'].status,
		ticketsData: state['Config'].ticketsData,
		site: state?.Config?.dataSettings?.site,
		staticDataStatus: op.get(state['Config'], 'staticData.cmsMessages.status'),
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		dataSettings: op.get(state['Config'], 'dataSettings'),
	};
};

const mapDispatchToProps = dispatch => ({
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
	updateUserCustomData: data => dispatch(deps.actions.UserData.updateUserCustomData(data)),
	updateTempUserData: () => dispatch(deps.actions.UserData.updateTempUserData()),
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});
class LogInForm extends Component {
	constructor(props) {
		super(props);

    this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
    this.passwordLessToken = this.parsedQs.token;

		this.state = {
			errors: {},
			submitSuccess: false,
			successURL: null,
			mounted: false,
      		passwordLessToken: this.parsedQs.token,
		};

		this.windowRef = React.createRef();
		this.successURLUpdated = false;
		this.userAvatar = false;

		logger.log('[LogInForm] constructor - props:%o, state:%o', props, this.state);
		// logger.log('[LogInForm] window.webview:%o', window.webview);
		this.loginAttempts = 0;
		this.patronInfoAttempts = 0;
		this.applicationInfoAttempts = 0;
		this.loginRetries = op.get(this.props, 'ticketsData.retryAttempts', 3);
		this.retryTimout;
		

		this.loadingRef = React.createRef();
		this.ssoHandled = false;


		/** setup event listener for our window messages */
		this.receiveEventsMessage = this.receiveEventsMessage.bind(this);
		this.notifyParentWindow = this.notifyParentWindow.bind(this);
	}

	componentDidMount() {
		window.addEventListener('message', this.receiveEventsMessage, false);
		window.addEventListener('load', this.notifyParentWindow);
		if (this.props.configStatus !== 'loaded') {
			this.props.loadConfig();
		} else {
			this.setState({
				mounted:true
			})

			if(this.state.passwordLessToken) {
				this.handlePasswordLessLogin(this.state.passwordLessToken, null);
			}
	  	}
	}
	componentWillUnmount() {
		// logger.log('[LogInForm] componentWillUnmount');
		window.removeEventListener('message', this.receiveMessage);
		window.removeEventListener('load', this.notifyParentWindow);

		this.setState({
			errors: {},
			submitSuccess: false,
			successURL: null
		});
	}

	notifyParentWindow() {
		logger.log('[LogInForm] notifyParentWindow window.opener:%o', window.opener);
		window?.opener?.postMessage('childLoaded', 'https://tickets-cdt.masters.com')
	}


	/**
	 *  receiveEventsMessage: this function receives the window message so that we can
	 *  process messages from the payment.js that the iframe uses.
	 */
	receiveEventsMessage(message) {

		//logger.log('[LogInForm] receiveEventsMessage message:%o', message);
		if (message.origin == 'https://tickets-cdt.masters.com' && message?.data?.payload?.action == 'login' && message?.data?.type == 'sso') {
			logger.log('[LogInForm] receiveEventsMessage message:%o', message);

			let auth = message.data.payload.data ? message.data.payload.data.split('|') : false;
			this.handleSso(auth);

		}
	}

	componentDidUpdate(prevProps,prevState) {
		// logger.log('[LogInForm] componentDidUpdate - this:%o', this);
		// logger.log('[LogInForm] componentDidUpdate - this.userAvatar:%o', this.userAvatar);

		if (prevState.mounted !== this.state.mounted || (prevProps.configStatus !== this.props.configStatus && this.props.configStatus == 'loaded')) {
			this.setState({
				mounted:true
			})
		}		

		if (!this.isWebview && !isEmpty(this.props.flowData) && !isEmpty(this.props.eventConfig) && this.state.mounted) {
			let applicationPhase = getApplicationPhase(this.props.userData.application, this.props.eventConfig);
			//logger.log('[LogInForm] componentDidUpdate - applicationPhase%o', applicationPhase);
			let auth = Cookies.get('tickets-auth') ? Cookies.get('tickets-auth').split('|') : false;
			logger.log('[LogInForm] componentDidUpdate - auth%o', auth);

			this.handleSso(auth);
			// let ssoData;
			// if (auth && !this.ssoHandled && auth.length >= 2) {
			// 	ssoData = {secureJwt: auth[0], secureRefreshToken: auth[1]}
			// 	logger.log('[LogInForm] componentDidUpdate - ssoData%o', ssoData);

			// 	this.ssoHandled = true;
			// 	if (auth[0] !== '' && auth[1] !== '' && auth[2]) {
			// 		if (checkJwtStatus(auth[0],this.props.ticketsData.jwtWillExpire) == false) {
			// 			this.getPatronData(ssoData.secureJwt, ssoData.secureRefreshToken);
			// 		}
			// 	} else {
			// 		this.setState({
			// 			errors: {
			// 				...this.state.errors,
			// 				login: this.props.messageSettings['login.sso_social_failure'] ? this.props.messageSettings['login.sso_social_failure'] :'There was an issue logging you in.  Please try again.',
			// 			},
			// 		}, () => {
			// 			// remove the cookie.  so user is not in an uh oh loop
			// 			//let domain = location.href.indexOf('localhost') == -1 ? this?.props?.dataSettings?.siteDomain : 'localhost';
			// 			let domain = location.href.indexOf('localhost') == -1 ? 'masters.com' : 'localhost';

			// 			Cookies.remove('tickets-auth', {domain});

			// 			MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
			// 				site: this.props.site,
			// 				response: 'failure',
			// 				responseData: auth[2],
			// 			});
			// 		});
			// 		// if (setSubmitting) {
			// 		// 	setSubmitting(false)
			// 		// }

			// 		if (this.loadingRef?.current) {
			// 			this.loadingRef.current.classList.remove('show');
			// 		}
			// 	}
			// }

			// if(op.get(this.state, 'submitSuccess', false) && 
			// 	!op.get(this.state, 'successURL', null) && 
			// 	(op.get(this.props, 'userData.privacyAgreement', '') === true || op.get(this.props, 'userData.privacyAgreement', '') === false) &&
			// 	!this.successURLUpdated
			// ) {
			// 	logger.log('[LogInForm] componentDidUpdate - Setting submitSuccess and successURL - this%o', this);
			// 	this.successURLUpdated = true;
				
			// 	this.setState({
			// 		successURL: getHomePage(this.props.userData, this.props.eventConfig, this.props.flowData, true)
			// 	})
			// }
		}
	}

	handleSso(auth) {
		// let applicationPhase = getApplicationPhase(this.props.userData.application, this.props.eventConfig);
		// logger.log('[LogInForm] componentDidUpdate - applicationPhase%o', applicationPhase);

		let ssoData;
			if (auth && !this.ssoHandled && auth.length >= 2) {
				ssoData = {secureJwt: auth[0], secureRefreshToken: auth[1]}
				logger.log('[LogInForm] componentDidUpdate - ssoData%o', ssoData);

				this.ssoHandled = true;
				if (auth[0] !== '' && auth[1] !== '' && auth[2]) {
					if (checkJwtStatus(auth[0],this.props.ticketsData.jwtWillExpire) == false) {
						this.getPatronData(ssoData.secureJwt, ssoData.secureRefreshToken);
					}
				} else {
					this.setState({
						errors: {
							...this.state.errors,
							login: this.props.messageSettings['login.sso_social_failure'] ? this.props.messageSettings['login.sso_social_failure'] :'There was an issue logging you in.  Please try again.',
						},
					}, () => {
						// remove the cookie.  so user is not in an uh oh loop
						//let domain = location.href.indexOf('localhost') == -1 ? this?.props?.dataSettings?.siteDomain : 'localhost';
						let domain = location.href.indexOf('localhost') == -1 ? 'masters.com' : 'localhost';

						Cookies.remove('tickets-auth', {domain});

						MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
							site: this.props.site,
							response: 'failure',
							responseData: auth[2],
						});
					});
					// if (setSubmitting) {
					// 	setSubmitting(false)
					// }

					if (this.loadingRef?.current) {
						this.loadingRef.current.classList.remove('show');
					}
				}
			}

			if(op.get(this.state, 'submitSuccess', false) && 
				!op.get(this.state, 'successURL', null) && 
				(op.get(this.props, 'userData.privacyAgreement', '') === true || op.get(this.props, 'userData.privacyAgreement', '') === false) &&
				!this.successURLUpdated
			) {
				logger.log('[LogInForm] componentDidUpdate - Setting submitSuccess and successURL - this%o', this);
				this.successURLUpdated = true;
				
				this.setState({
					successURL: getHomePage(this.props.userData, this.props.eventConfig, this.props.flowData, true)
				})
			}

	}

	handleAutoFill(e) {
		// logger.log('[LogInForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

	togglePassword() {
		this.setState({
			password_show: this.state.password_show ? false : true,
		}, () => {
			MeasurementUtils.dispatchMeasurementCall('togglePassword', {
				site: this.props.site,
				show: this.state.password_show
			});
		});
	}

	handleUpdateUserData(userData, loginType) {
		let userdata = {
			...userData,
			loginType: loginType,
		};

		this.props.updateUserData(userdata);
		this.props.updateTempUserData();
	}

	/**
	 * get random number between 1-18 to set avatar
	 */
	randomIntFromInterval(min, max) {
		// min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	getPatronData = (secureJwt, secureRefreshToken, formValues, setSubmitting) => {
		let { ticketsData } = this.props;
		let loginRes = 	jwt_decode(secureJwt);
		let auth = Cookies.get('tickets-auth') ? Cookies.get('tickets-auth').split('|') : false;

		logger.log('[LogInForm] getPatronData - setSubmitting:%o', setSubmitting);

		axios({
			method: 'get',
			url: ticketsData.patronInfo,
			headers: {Authorization: `Bearer ${secureJwt}` },
			//headers: {Authorization: `Bearer ${loginRes.data.token}` },
		})
			.then((patronRes) => {
				//throw({error:{response:{status:500}}});

				if (patronRes.status == 200) {
					logger.log('[LogInForm] handleLogIn patronRes:%o', patronRes.data);
					this.patronInfoAttempts = 0;

					if (patronRes.data.errorCode) {
						this.setState({
							errors: {
								...this.state.errors,
								login: patronRes.data.errorCode == 650 ? messageSettings['login.inactive_account'] : patronRes.data.errorMessage
							}
						}, () => {
							if (setSubmitting) {
								setSubmitting(false)
							}
							MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
								site: this.props.site,
								response: 'failure',
								responseData: patronRes,
							});
						});
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
					} else {
						if (!isEmpty(patronRes.data)) {
							axios({
								method: 'get',
								url: ticketsData.application,
								headers: {Authorization: `Bearer ${secureJwt}` },
							})
								.then(appRes => {
									if (patronRes.status == 200) {
										logger.log('[LogInForm] handleLogIn appRes:%o', appRes.data);
										this.applicationInfoAttempts = 0;
	
										this.setState({
											errors: {},
											submitSuccess: true
										}, () => {
											// remove the cookie.  so logout works
											//let domain = location.href.indexOf('localhost') == -1 ? this?.props?.dataSettings?.siteDomain : 'localhost';
											let domain = location.href.indexOf('localhost') == -1 ? 'masters.com' : 'localhost';

											Cookies.remove('tickets-auth', {domain});
											if (setSubmitting) {
												setSubmitting(false)
											}
											if (this.loadingRef.current) {
												this.loadingRef.current.classList.remove('show');
											}
	
											let userData = {
												...loginRes,
												...patronRes.data,
												secureJwt,
												secureRefreshToken,
												application: {
													...appRes.data
												}
											}
			
											logger.log('[LogInForm] handleLogIn userData:%o', userData);
	
											this.handleUpdateUserData(userData, 'custom');
	
											MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
												site: this.props.site,
												response: 'success',
												userID: loginRes.userID,
												sso: auth && auth.length > 0 ? true : false
												//responseData: loginRes,
	
											});
										});
									}
								})
								.catch(error => {
									this.applicationInfoAttempts++;
									logger.error(
										'[LogInForm] handleLogIn request application info error:%o, applicationInfoAttempts:%o',
										error,
										this.applicationInfoAttempts
									);
									if (this.applicationInfoAttempts >= this.loginRetries) {
	
										this.setState({
											errors: {
												...this.state.errors,
												application: this.props.messageSettings['general.service_error'] ? this.props.messageSettings['general.service_error']?.replace("{0}","L1") :'There was an issue logging you in.  Please try again.',
											},
										}, () => {
											MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
												site: this.props.site,
												response: 'failure',
												responseData: error,
											});
										});
										if (setSubmitting) {
											setSubmitting(false)
										}
	
										if (this.loadingRef.current) {
											this.loadingRef.current.classList.remove('show');
										}
										
									} else {
										this.retryTimout = setTimeout(() => {
											logger.error(
												'[LogInForm] retry handleLogIn applicationInfoAttempts:%o',
												this.applicationInfoAttempts
											);
											if (formValues) {
												this.handleLogIn(formValues, setSubmitting);
											}
										}, ticketsData.retryWait * 1000);
									}
								});
						} else {
							this.setState({
								errors: {
									...this.state.errors,
									login: this.props.site == 'psb' && this.props.messageSettings['login.invalid_account'] ? this.props.messageSettings['login.invalid_account'] : this.props.messageSettings['general.service_error']?.replace("{0}","L2")
								}
							}, () => {
								if (setSubmitting) {
									setSubmitting(false)
								}
								MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
									site: this.props.site,
									response: 'failure',
									responseData: patronRes,
								});
							});
							if (this.loadingRef.current) {
								this.loadingRef.current.classList.remove('show');
							}
						}
						
					}
				}
			})
			.catch((error) => {
				this.patronInfoAttempts++;
				logger.error(
					'[LogInForm] handleLogIn request patron info patronInfoAttempts:%o, this.loginRetries:%o, error:%o',
					this.patronInfoAttempts,
					this.loginRetries,
					error,
				);
				if (this.patronInfoAttempts >= this.loginRetries) {
					if (setSubmitting) {
						setSubmitting(false)
					}
					if (this.loadingRef.current) {
						this.loadingRef.current.classList.remove('show');
					}

					this.setState({
						errors: {
							...this.state.errors,
							login: this.props.messageSettings['general.service_error'] ? this.props.messageSettings['general.service_error']?.replace("{0}","L3") :'There was an issue logging you in.  Please try again.',
						},
					}, () => {
						MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
							site: this.props.site,
							response: 'failure',
							responseData: error,
						});
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
					});
				} else {
					this.retryTimout = setTimeout(() => {
						logger.error(
							'[LogInForm] retry handleLogIn patronInfoAttempts:%o',
							this.patronInfoAttempts
						);
						if (formValues) {
							this.handleLogIn(formValues, setSubmitting);
						}
						
					}, ticketsData.retryWait * 1000);
				}
			});
	}

	handleLogIn = (values, setSubmitting) => {
		logger.log('[LogInForm] handleLogIn - values:%o', values);
		logger.log('[LogInForm] handleLogIn - props:%o', this.props);
		logger.log('[LogInForm] handleLogIn - setSubmitting:%o', setSubmitting);

		let { ticketsData } = this.props;

		// showLoading indicator
		if (this.loadingRef.current) {
			this.loadingRef.current.classList.add('show');
		}

		axios({
			method: 'post',
			url: ticketsData.authentication,
			data: {
				provider: ticketsData.provider,
				username: values.emailAddress,
				password: values.password,
			},
		})
			.then((loginRes) => {
				//setSubmitting(false);
				//throw({error:{response:{status:500}}});
				logger.log('[LogInForm] handleLogIn loginRes:%o', loginRes);
				//logger.log('[LogInForm] handleLogIn - setSubmitting:%o', setSubmitting);

				if (loginRes.status == 200) {
					this.loginAttempts = 0;
					if (loginRes.data.statusCode == 'ok') {
						this.getPatronData(loginRes.data.secureJwt, loginRes.data.secureRefreshToken, values, setSubmitting)
					} else if (loginRes.data.statusCode == 'reset') {
						this.setState({
							errors: {
								...this.state.errors,
								login: this.props.messageSettings['login.password_reset_required'],
							},
						}, () => {
							if (setSubmitting) {
								setSubmitting(false)
							}
							if (this.loadingRef.current) {
								this.loadingRef.current.classList.remove('show');
							}
							MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
								response: 'failure',
								responseData: this.state.errors,
								site: this.props.site
							});
						});
					} else {
						this.setState({
							errors: {
								...this.state.errors,
								login: this.props.messageSettings['general.service_error'] ? this.props.messageSettings['general.service_error']?.replace("{0}","L4") :'There was an issue logging you in.  Please try again.',
							},
						}, () => {
							if (setSubmitting) {
								setSubmitting(false)
							}	
							if (this.loadingRef.current) {
								this.loadingRef.current.classList.remove('show');
							}
							MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
								response: 'failure',
								responseData: this.state.errors,
								site: this.props.site
							});
						});
					}
				} else {
					this.loginAttempts++;
					logger.error(
						'[LogInForm] handleLogIn loginRes not 200 error, loginAttempts:%o',
						loginRes.status,
						this.loginAttempts
					);
					if (this.loginAttempts >= this.loginRetries) {
						this.setState({
							errors: {
								...this.state.errors,
								login: this.props.messageSettings['general.service_error'] ? this.props.messageSettings['general.service_error']?.replace("{0}","L5") :'There was an issue logging you in.  Please try again.',
							},
						}, () => {
							if (setSubmitting) {
								setSubmitting(false)
							}
							if (this.loadingRef.current) {
								this.loadingRef.current.classList.remove('show');
							}
							MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
								response: 'failure',
								responseData: this.state.errors,
								site: this.props.site
							});
						});
					} else {
						this.retryTimout = setTimeout(() => {
							logger.error('[LogInForm] retry handleSubmit loginAttempts:%o', this.loginAttempts);
							this.handleLogIn(values, setSubmitting);
						}, ticketsData.retryWait * 1000);
					}
				}
			})
			.catch((error) => {
				logger.error('[LogInForm] handleSubmit login error:%o', error);
				if (error.response) {
					if (setSubmitting) {
						setSubmitting(false)
					}
					if (this.loadingRef.current) {
						this.loadingRef.current.classList.remove('show');
					}
					if (error.response.status == '403') {
						this.setState({
							errors: {
								...this.state.errors,
								login: this.props.messageSettings['login.invalid_account'] ? this.props.messageSettings['login.invalid_account'] : 'Invalid Email Address or Password',
							},
						}, () => {
							if (setSubmitting) {
								setSubmitting(false)
							}
							if (this.loadingRef.current) {
								this.loadingRef.current.classList.remove('show');
							}
							MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
								response: 'failure',
								responseData: this.state.errors,
								site: this.props.site
							});
						});
					}
				} else {
					this.setState({
						errors: {
							...this.state.errors,
							login: this.props.messageSettings['general.service_error'] ? this.props.messageSettings['general.service_error']?.replace("{0}","L6") :'There was an issue logging you in.  Please try again.',
						},
					}, () => {
						if (setSubmitting) {
							setSubmitting(false)
						}
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
						MeasurementUtils.dispatchMeasurementCall('UserLogInSubmit', {
							response: 'failure',
							responseData: this.state.errors,
							site: this.props.site
						});
					});
				}
			});
	}

	getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

	onCreateAccount = () => {
		this.props.onViewChange('createaccount');
	}

  handlePasswordLessLogin = (token, setSubmitting) => {
    logger.log('[LogInForm] handlePasswordLessLogin - token:%o', token, token.length);

    let { ticketsData } = this.props;
    let urlToken;

    urlToken = ticketsData.passwordLessAuthToken + token;

    logger.log('[LogInForm] handlePasswordLessLogin - urlToken:%o', urlToken);

    	// showLoading indicator
		if (this.loadingRef.current) {
			// show loading dots on parent
			this.props.showLoading(true);
			this.loadingRef.current.classList.add('show');
		}

    axios({
      method: 'post',
      url: urlToken,
    })
    .then(loginRes => {
      logger.log('[LogInForm] handlePasswordLessLogin loginRes:%o', loginRes);
      if(loginRes.status == 200) {
        if(loginRes.data.statusCode == 'ok') {
          this.getPatronData(loginRes.data.jwts.secureJwt, loginRes.data.jwts.secureRefreshToken);
        }
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            login: this?.props?.messageSettings?.['login.secure_login_link_invalid_token'] ?? this.props.messageSettings['general.service_error'],
          },
        }, () => {
		  if (this.loadingRef.current) {
			// lets remove the query string
			window.history.replaceState({}, '', window.location.pathname);

			// this is a call to the parent to remove loading dots
			this.props.showLoading(false);
            this.loadingRef.current.classList.remove('show');
          }
          MeasurementUtils.dispatchMeasurementCall('PasswordLessLogin', {
            response: 'failure',
            responseData: this.state.errors,
            site: this.props.site
          });
        });
      }
    })
    .catch((error) => {
      logger.error('[LogInForm] handlePasswordLessLogin login error.response:%o', error.response);
      if (error.response) {
        if (setSubmitting) {
          setSubmitting(false)
        }
        if (this.loadingRef.current) {
          this.loadingRef.current.classList.remove('show');
        }
		this.setState({
		errors: {
			...this.state.errors,
			login: (error.response.status == '400' || error.response.status == '503' || error.response.status == '403') 
				? this?.props?.messageSettings?.['login.secure_login_link_invalid_token'] ?? this.props.messageSettings['general.service_error'] 
				: this.props.messageSettings['general.service_error'] ,
		},
		}, () => {
		if (setSubmitting) {
			setSubmitting(false)
		}
		if (this.loadingRef.current) {
			// remove query string token
			window.history.replaceState({}, '', window.location.pathname);

			// remove loading dots in parent component
			this.props.showLoading(false);
			this.loadingRef.current.classList.remove('show');
		}

		MeasurementUtils.dispatchMeasurementCall('PasswordLessLogin', {
			response: 'failure',
			responseData: this.state.errors,
			site: this.props.site
		});
		});
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            login: this?.props?.messageSettings?.['login.secure_login_link_invalid_token'] ?? this.props.messageSettings['general.service_error'],
          },
        }, () => {
          if (setSubmitting) {
            setSubmitting(false)
          }
          if (this.loadingRef.current) {
			// remove query string token
			window.history.replaceState({}, '', window.location.pathname);

			// remove loading dots in parent component
			this.props.showLoading(false);
            this.loadingRef.current.classList.remove('show');
          }
          MeasurementUtils.dispatchMeasurementCall('PasswordLessLogin', {
            response: 'failure',
            responseData: this.state.errors,
            site: this.props.site
          });
        });
      }
    });

  }

	render() {
		logger.log('[LogInForm] render - this: %o', this);

		let passwordShow = op.get(this.state, 'password_show', false) === true ? true : false;

		this.LogInValues = {
			emailAddress: this.parsedQs.email ? decodeURIComponent(this.parsedQs.email).replace(" ","+") : '',
			password: '',
		};

		let LogInSchema = yup.object({
			emailAddress: yup
				.string()
				.email('Please supply a valid Email')
				.required('Email is required'),
			password: yup.string().required('Password is required'),
		});

		if (
			this.state.submitSuccess === true &&
			op.get(this.state, 'successURL', null)
		) {
			return <Redirect push to={this.state.successURL} />;
		}

			return ( 
				<div className="log-in-container">
					<div className="form-title">Log In</div>
					<Formik
						initialValues={this.LogInValues}
						validationSchema={LogInSchema}
						onSubmit={(values, { setSubmitting }) => {
							if((values.emailAddress == '' || values.password == '')) {
								setSubmitting(false);
							} else {
								setSubmitting(true);
								this.handleLogIn(values, setSubmitting);
							}
						}}
						enableReinitialize={true}
						key="logIn">
						{formProps => {
							// logger.log('[LogInForm] render - formProps:%o', formProps);
							return (
								<Form id="logInForm">
									{writeErrors(formProps, this.state.errors)}

									<FormEmail
										id="emailAddress"
										name="emailAddress"
										label="Email Address"
										labeltype="float"
										onAnimationStart={e => this.handleAutoFill(e)}
										onAnimationEnd={e =>
											e.animationName === 'onAutoFillStart'
												? (formProps.touched.emailAddress = true)
												: (formProps.touched.emailAddress = false)
										}
										onFocus={e => this.handleFocus(e, true)}
										onBlur={e => this.handleFocus(e, false, formProps)}
									/>

									<FormPassword
										id="password"
										name="password"
										label="Password"
										labeltype="float"
										type={passwordShow ? "text" : "password"}
										passwordshow={passwordShow}
										instructions="false"
										onAnimationStart={e => this.handleAutoFill(e)}
										onAnimationEnd={e =>
											e.animationName === 'onAutoFillStart'
												? (formProps.touched.password = true)
												: (formProps.touched.password = false)
										}
										onFocus={e => this.handleFocus(e, true)}
										onBlur={e => this.handleFocus(e, false, formProps)}
										onTogglePassword={() => this.togglePassword() }
									/>

									<div className="page-actions">
										{/** 
										 * in order to send form on hitting Enter key, need to use <input type="submit" /> 
										*/}
										<input 
											type="submit" 
											disabled={ (!formProps.isValid || !formProps.dirty || formProps.isSubmitting)}
											className={`button submit full ${!formProps.isValid || !formProps.dirty || formProps.isSubmitting ? 'disabled' :''}`}
											value="Log In" 
										/>
									</div>
								</Form>
							);			
						}}
					</Formik>

					{
						!isEmpty(this.props.flowData) ? (
							<div className="page-links">
								<div className="forgot-password-link">
									<EventsLink
										to={op.get(this.props, 'flowData.actions.login.forgotPassword', null)}
										style="action-link">
										Forgot password?
									</EventsLink>
								</div>
                <div className='separator-with-word'>
                  OR
                </div>

                <div className='magic-login-link'>
                  <Button buttonText={this.props.messageSettings['login.secure_login_button']}
                    to={op.get(this.props, 'flowData.actions.login.magicLink', null)}
                    style="full green"
                  >
                  </Button>

                </div>
		
								<div className="create-account-link">
									<a className="action-link" onClick={this.onCreateAccount}>
										Create Account
									</a>
								</div>
		
								<div className="form-text">
									* Information collected by Augusta National Golf Club is used solely for ticketing and Tournament information and will not be used, shared, sold, or distributed for any other reason.
								</div>
							</div>
						)
						: null
					}
					{this.getLoadingIndicator()}
				</div>
			);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
