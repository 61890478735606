import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getCleanLink, getAppLink } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

export default class EventsLink extends Component {
	constructor(props) {
		super(props);

		let link = {
			url: '',
			external: false,
		};

		if (props.to) {
			if (window.webview) {
				link = getAppLink(props.to, props.title ? props.title : '');
			} else if (!props.external) {
				link = getCleanLink(props.to);
			} else {
				link = {
					url: props.to,
					external: true,
				};
			}
		}

		this.state = {
			...props,
			link: link,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.to !== this.props.to || prevProps.external !== this.props.external) {
			let link = {
				url: '',
				external: false,
			};
	
			if (this.props.to) {
				if (window.webview) {
					link = getAppLink(
						this.props.to,
						this.props.title ? this.props.title : ''
					);
				} else if (!this.props.external) {
					link = getCleanLink(this.props.to);
				} else {
					link = {
						url: this.props.to,
						external: true,
					};
				}
			}
	
			this.setState({ link }); // Assuming link is stored in the state
		}
	}

	openWindow(url) {
		window.open(url, '', this.state.options);
	}

	onClick(args) {
		logger.log('[EventsLink] onClick - args:%o', args);
		MeasurementUtils.dispatchMeasurementCall(
			MeasurementUtils.ACTION_TYPES.onPageClick,
			{ args: args }
		);
	}

	render() {
		//logger.log('[EventsLink] render - props:%o', this);
		// logger.log('[EventsLink] render - rend:%o', ( this.state.link && this.state.link.url && this.state.children));

		if (this.props.info == 'logout') {
			logger.log('[EventsLink] render - props:%o', this);
		}
		if (this.state.link && this.state.link.url && this.state.link.url !== '' && this.state.children) {
			if (this.state.popup) {
				let openStyle = {
					cursor: 'pointer',
				};

				return (
					<span
						style={openStyle}
						onClick={() => this.openWindow(this.state.link.url)}>
						{this.state.children}
					</span>
				);
			} else if (window.webview && !this.state.link.external) {
				return (
					<a
						href={this.state.link.url}
						className={this.state.style ? this.state.style : ''}
						target="_self"
						aria-label={this.state.label ? this.state.label : ''}>
						{this.state.children}
					</a>
				);
			} else if (this.state.link.external) {
				let className = this.state.style ? this.state.style : '';
				if (this.state.link.url.indexOf('.pdf') > -1) {
					className = ''
						? (className = 'download')
						: className + ' download';
				} else {
					className = ''
						? (className = 'external')
						: className + ' external';
				}
				return (
					<a
						href={this.state.link.url}
						className={className}
						target="_blank"
						aria-label={this.state.label ? this.state.label : ''}>
						{this.state.children}
					</a>
				);
			} else {
				return (
					<Link
						to={this.state.link.url}
						className={this.state.style ? this.state.style : ''}
						onClick={this.props.onClick}
						aria-label={this.state.label ? this.state.label : ''}>
						{this.state.children}
					</Link>
				);
			}
		} else if (this.props.onClick) {
			
			return (
				<a
					href=""
					className={this.state.style ? this.state.style : ''}
					target="_self"
					onClick={(e) => {this.props.onClick(), e.preventDefault()}}
					data="onclickOnly"
					aria-label={this.state.label ? this.state.label : ''}>
					{this.state.children}
				</a>
			)
		} else if (this.state.children) {
			return <Fragment>{this.state.children}</Fragment>;
		} else {
			return null;
		}
	}
}
