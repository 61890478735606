import React, { Component, Fragment } from 'react';
import JSXParser from 'react-jsx-parser';
import ErrorBoundary from 'shared/general/ErrorBoundary';

export default class CmsMessageContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			message: null,
			error: false,
		};
	}

	componentDidMount() {
		// load the config web file here.
		logger.log('[CmsMessageContent] - componentDidMount props:%o, state', this.props, this.state);

		if (this.props.siteConfigStatus == 'loaded' && this.props.staticDataStatus == 'loaded') {
			this.setState({
				isReady:true
			})
		} else {
			this.props.loadConfig();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[CmsMessageContent] - componentDidUpdate props:%o, isReady:%o, message:%o', this.props, this.state.isReady, this.state.message);

		// is config web loaded?
		if (this.props.siteConfigStatus == 'loaded' && this.props.staticDataStatus == 'loaded' && !this.state.isReady) {
			//logger.log('[CmsMessageContent] - componentDidUpdate set isReady:%o', this.state.isReady);
			this.setState({
				isReady: true,
			});
		}

		//isReady changed from false to true.  We are ready to do something now
		if (prevState.isReady !== this.state.isReady && this.state.isReady == true) {
			this.transformMessage();
		}

		if(prevProps.id != this.props.id && this.state.isReady == true) {
			this.transformMessage();
		}
	}

	replaceAll(str = '',data = {}){
		var re = new RegExp("\\{" + Object.keys(data).join("\\}|\\{") + "\\}","gi");
		logger.log('[CmsMessageContent] - replaceAll str:%o', str);

		//logger.log('[CmsMessageContent] - replaceAll re:%o', re);

		let newString =  str.replace(re, function(matched){
			//logger.log('[CmsMessageContent] - replaceAll matched:%o', matched);
			let key = matched.replace("{","").replace("}","");

			//logger.log('[CmsMessageContent] - replaceAll key:%o', key);
			return data[key] ? data[key] : str;
		});
		//logger.log('[CmsMessageContent] - replaceAll newString:%o', newString);
		return newString;

	}

	transformMessage() {
		const { id, data = [] } = this.props;
		logger.log('[CmsMessageContent] - transformMessage id:%o, data:%o', id, data);

		let tempMessage = this.props.messageSettings[id];
		logger.log('[CmsMessageContent] - transformMessage tempMessage:%o', tempMessage);

		// replace email if we need to
		if (tempMessage) {
			//let message = this.replaceAll(tempMessage, data)?.replaceAll("<hr>","<hr />")?.replaceAll("<br>","<br />");

			let message = this.replaceAll(tempMessage, data)?.replace(/<hr>/g,"<hr />")?.replace(/<br>/g,"<br />");
			logger.log('[CmsMessageContent] - transformMessage message:%o', message);

			this.setState({
				message,
			});
		} else {
			logger.error(`Unable to find a corresponding message in config. id:${id}`);
			this.setState({
				error:true,
			});
		}
	}

	onError(e) {
		logger.error('[CmsMessageContent] onError - e: %o', e);
		throw new Error("Unable to render this message.  Most likely a tag is not being replaced with data.  Check to see if props.data has the correct content");
	}

	render() {
		logger.log('[CmsMessageContent] render - this.props: %o, this.state', this.props, this.state);
		logger.log('[CmsMessageContent] render - message', this.props, this.state);

		return (
			<>
				{this.state.message ? (
					this.props.textOnly ? (
						this.state.message
					) : (
						<section data-cms={this.props.id} className="message-content">
						<ErrorBoundary message="Unable to render message content.">
							<JSXParser 
								autoCloseVoidElements={true}
								jsx={this.state.message}
								onError={(e) => this.onError(e)}
							/>
						</ErrorBoundary>
					</section>
					)
				)
				: null}
				{this.state.error ? (
					<section data-cms-error={this.props.id} className="message-content">
						Unable to render message content.
					</section>

				) : null}
			</>

		);
	}
}

CmsMessageContent.defaultProps = {};


