import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { PlugableProvider } from 'dotix/components/Plugable';
import { Provider } from 'react-redux';
import createStore from 'dotix/createStore';
import ClientRouter from 'dotix/components/Router';
// awt:  removing devtools.  I don't think we need this and can use chrome devtools extension
//import DevTools from 'dotix/components/DevTools';
import deps, { getComponents } from 'dependencies';

deps.init();

const store = createStore();
const bindPoints = [];

const elements = typeof document !== 'undefined' ? Array.from(document.querySelectorAll('component')) : [];

if (elements.length > 0) {
  const elms = elements.map(elm => {
    const path = elm.getAttribute('path');
    const type = elm.getAttribute('type');
    return { path, type };
  });

  const components = getComponents(elms);

  elements.forEach(elm => {
    const type = elm.getAttribute('type');
    if (!components.hasOwnProperty(type)) {
      return;
    }
    const params = Array.from(elm.attributes).reduce((acc, attr) => {
      if (attr.name !== 'type' && attr.name !== 'path') {
        acc[attr.name] = attr.value;
      }
      return acc;
    }, {});

    const children = elm.innerHTML;
    if (children) {
      params['children'] = children;
    }

    const cmp = React.createElement(components[type], params);
    bindPoints.push({ component: cmp, element: elm });
  });
}

export const App = () => {
  if (bindPoints.length > 0) {
    bindPoints.forEach(item => {
      const root = createRoot(item.element);
      root.render(
        <Provider store={store}>
          <PlugableProvider {...deps.plugableConfig}>
            <Fragment>{item.component}</Fragment>
          </PlugableProvider>
        </Provider>
      );
    });
  }

  const routerTarget = document.getElementById('root');
  const root = createRoot(routerTarget);
  root.render(
    <Provider store={store}>
      <PlugableProvider {...deps.plugableConfig}>
        {/* <DevTools /> */}
        <ClientRouter />
      </PlugableProvider>
    </Provider>
  );
};
