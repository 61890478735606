import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import parseInt from 'lodash/parseInt';
import {
	deviceDetect,
	isWearable,
	isMobileOnly,
	isTablet,
	isBrowser,
	isSmartTV,
} from 'react-device-detect';
import { isEmpty, transform, isEqual, isArray, isObject } from 'lodash';

import axios from 'axios';


export const convertEpochToRelativeTime = epoch => {
	if (epoch !== '' && epoch !== undefined) {
		let publishedTime = parseInt(epoch);

		// make epoch value to be 13 disits
		if (('' + publishedTime).length === 10) {
			publishedTime = publishedTime * 1000;
		}

		let relTime = '';

		if (publishedTime != undefined) {
			let convertedPubTime = moment(publishedTime);
			let convertedPubMonth = moment(convertedPubTime).get('month');
			let convertedPubDate = moment(convertedPubTime).get('date');

			let currentTime = moment();
			let currentMonth = moment(currentTime).get('month');
			let currentDate = moment(currentTime).get('date');

			// customize moment text to show #s, #m, #d, #y
			// instead of displaying a day ago or 10 hours ago
			moment.updateLocale('en', {
				relativeTime: {
					future: 'in %s',
					past: '%s ago',
					s: '1s',
					ss: '%d seconds',
					m: '1m',
					mm: '%dm',
					h: '1h',
					hh: '%dh',
					d: '1d',
					dd: '%dd',
					M: '1m',
					MM: '%dm',
					y: '1y',
					yy: '%dy',
				},
			});

			// on the day of publication, show #s, #m, #h ago text
			// after that day, show on the format Wed, Apr 08
			if (
				convertedPubMonth + convertedPubDate ===
				currentMonth + currentDate
			) {
				relTime = convertedPubTime.fromNow();
			} else {
				relTime = moment(convertedPubTime._d).format('ddd, MMM DD');
			}
			return relTime;
		} else {
			return '';
		}
	}
	return '';
};

export const getScreenType = () => {
	if (isMobileOnly) {
		return 'Phone';
	} else if (isTablet) {
		return 'Tablet';
	} else if (isBrowser) {
		return 'PC';
	} else if (isWearable) {
		return 'Wearable';
	} else if (isSmartTV) {
		return 'SmartTv';
	}
};

export const getDeviceDetails = () => {
	return deviceDetect();
};

export const getEpoch = dateObj => {
	//let dt = new Date();
	//dt.setMinutes( dt.getMinutes() - 2 );
	let epoch = Math.floor(dateObj.getTime());
	return epoch;
};

export const postEventsMessage = (type, source, payload) => {
	eventsChannel.postMessage({
		type,
		source,
		payload
	});
}
/**
 * function:  replaceAll
 * 
 * @param {*} str 
 * @param {*} mapObj 
 * @returns 
 * 
 * this function takes a string and replaces values surrounded by brackets with values in the mapObj.
 * 
 * ex:
 * let str = "I want to I want to <action><when>."
 * let mapObj = {
 * 		action:"retire",
 * 		when:"tomorrow"
 * 	}
 * 
 * let newString = replaceAll(str, mapObj)
 * 
 */
export const replaceAll = (str="", mapObj={}) => {
	var re = new RegExp('<' + Object.keys(mapObj).join('>|<') + '>', 'gi');
	//logger.log('[replaceAll] - str:%o', str);
	//logger.log('[replaceAll] - re:%o', re);

	let newString = str.replace(re, function(matched) {
		//logger.log('[replaceAll] - matched:%o', matched);
		let key = matched.replace('<', '').replace('>', '');

		//logger.log('[replaceAll] - key:%o', key);
		if (null !== mapObj[key] && mapObj[key] !== '') {
			return mapObj[key];
		} else {
			return '';
		}
	});
	logger.log('[replaceAll] - newString:%o', newString);
	
	return newString;
}

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
 export const difference = (origObj={}, newObj={}) => {
	//logger.log('[difference] - origObj:%o, newObj:%o', origObj, newObj);

	function changes(newObj, origObj) {
	  let arrayIndexCounter = 0

	  return transform(newObj, function (result, value, key) {
		let origVal = origObj[key];
		let newVal = value;

		//logger.log('[difference] - typof key:%o,  newVal:%o, origVal:%o', key, typeof newVal, typeof origVal);

		// this is complicated and i don't know what i'm doing.  If the value is a number or string then convert to string, trim and make lowercase.
		// Otherwise if the newVal or origVal is null or undefined, make it an empty string.  or just use the value.
		// just need to make sure we aren't comparing empty string to null
		typeof newVal == 'string' || typeof newVal == 'number' ? newVal = value.toString().toLowerCase().trim() : null !== newVal && typeof newVal !== 'undefined' ? newVal = value : newVal = '';
		typeof origVal == 'string' || typeof origVal == 'number' ? origVal = origVal.toString().toLowerCase().trim() : null !== origVal && typeof origVal !== 'undefined' ? origVal = origObj[key] : origVal = '';

		//logger.log('[difference] - key:%o,  newVal:%o, origVal:%o', key, newVal, origVal);
		
		if (!isEqual(newVal, origVal)) {
		  let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
		  result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
		}
	  })
	}
	return changes(newObj, origObj)
  }

export const getApplicationPhase = (application = {}, eventData = {}) => {
	logger.log('[getApplicationPhase] - application:%o, eventData:%o', application, eventData);

	let phase = "registration";
	//eventData.year = 2021;
	// application.applicationStatus = 2;
	// eventData.allowPayment = true;

	// eventData.applicationEdit = true;
	// eventData.applicationAdd = true;
	//eventData.year = 2023;

	// add registrations only
	if (eventData.eventName == 'psb' && (isEmpty(application) || application.year !== eventData.year)) {
		phase == "registration"
	}

	else if (!eventData.applicationEdit && !eventData.applicationAdd && (application.errorCode || isEmpty(application) || !application.applicationStatus)) {
		phase = "registration"
	}

	// applications are on
	else if (eventData.applicationAdd && eventData.applicationEdit && (application.errorCode || isEmpty(application) || !application.applicationStatus)) {
		phase = "applicationAdd"
	} 
	
	// application submitted and editable
	else if (eventData.applicationEdit && application.applicationStatus == 1) {
		phase = "applicationSubmittedEdit"
	} 

	// application submitted and editable
	else if (!eventData.applicationEdit && application.applicationStatus == 1) {
		phase = "applicationSubmittedNoEdit"
	} 

	// user notified winner
	else if (!eventData.applicationAdd && !eventData.applicationEdit  &&  (application.applicationStatus == 2 || application.applicationStatus == 6)) {
		phase = "applicationWinner"
	}

	// user notified loser
	else if (!eventData.applicationAdd && !eventData.applicationEdit &&  application.applicationStatus == 4) {
		phase = "applicationLoser"
	} 

	// user paid
	else if (!eventData.applicationAdd && !eventData.applicationEdit && application.applicationStatus == 3) {
		phase = "applicationPaid"
	}
	
	else if (!eventData.applicationAdd && !eventData.applicationEdit && !eventData.allowPayment && application.applicationStatus !== 0 && application.applicationStatus !== 1 ) {
		phase = "offSeason"
	} 
	
	else {
		logger.log('[getApplicationPhase] - return default phase:%o',phase);
	}

	logger.log('[getApplicationPhase] - application:%o, eventData:%o, phase:%o', application, eventData, phase);

	return phase;
}

export const getHomePage = (userData = {}, eventData = {}, flowData = {}, isLogin) => {
	let applicationPhase = getApplicationPhase(userData.application, eventData);
	let homePage = false;
	let userHasDob = userData.birthYear !== 0
	
	//debugger;
	//eventData.year = 2023;
	/** if privacy agreement has not been accepted, always go to the privacy page */
	if(userData.privacyAgreement === false) {
		homePage = flowData.login.privacy
	} else {
		if (eventData.eventName == 'psb') {
			if (applicationPhase == 'registration' && userData.application && userData.application.year !== eventData.year) {
				homePage = flowData.login.returnUserNewApplication
			}
			else if (applicationPhase == 'applicationWinner') {
				homePage = flowData.login.unpaidWinnerHome
			}
			else if (applicationPhase == 'applicationPaid') {
				homePage = flowData.login.paidWinnerHome
			}
			else {
				homePage = false
			}
		}
		else {		
			/** user does not have an address or dob (application is not open && user doesn't have an application)  */
			/** modifying so that any user with no address or has this hardcoded value goes to profile regardless of application */
			//if((!userData.addressHash || !userHasDob) && (applicationPhase == 'registration' || applicationPhase == 'applicationAdd')) {
			if (!userData.addressHash || !userHasDob || (userData.addressHash && userData.addressHash.startsWith("ADDRESS_HASH_"))) {
				homePage = flowData.login.profile
			} 
			/**  user has an address and dob and phase is registration */
			else if (userData.addressHash && userHasDob && applicationPhase == 'registration') {
				homePage = flowData.login.returnUserNewApplication
			} 
			/**  user has an address dob and and application is open and user does not have an application */
			else if(userData.addressHash && userHasDob && applicationPhase == 'applicationAdd') {
				homePage = flowData.login.returnUserNewApplication
			} 
			/**  user has an address and application is open and user already submitted an application */
			else if(userData.addressHash && userHasDob && 
				(applicationPhase == 'applicationSubmittedEdit' || applicationPhase == 'applicationSubmittedNoEdit')) {
				homePage = flowData.login.returnUserSubmittedApplication
			} 
			/** is login and user has been selected as a winner and has not paid */
			else if(isLogin && applicationPhase == 'applicationWinner' && userData.eventName !== 'psb') {
				homePage = flowData.login.winnerSplash
			} 

			/** is not login and user has been selected as a winner */
			else if(!isLogin && applicationPhase == 'applicationWinner'
			) {
				homePage = flowData.login.unpaidWinnerHome
			} 

			/** user is a paid winner */
			else if(applicationPhase == 'applicationPaid') {
				homePage = flowData.login.paidWinnerHome
			} 

			/** user has not been selected as a winner and has not seen splash page */
			else if(isLogin && userData.application.hasViewedLost === false && applicationPhase == 'applicationLoser'
			) {
				homePage = flowData.login.loserSplash
			} 

			/** user has not been selected as a winner and has seen splash page */
			else if((!isLogin || userData.application.hasViewedLost === true) && applicationPhase == 'applicationLoser'
			) {
				homePage = flowData.login.loserHome
			} 

			/** application process is not open */
			else if(applicationPhase == 'offSeason') {
				homePage = flowData.login.offSeason
			} 
			else {
				logger.log('[LogInForm] componentDidUpdate - Setting submitSuccess and successURL - no valid success');
				homePage = false
			} 
		}
	}

	logger.log('[getHomePage] - homePage:%o', homePage);

	return homePage
}

export const accountChanged = (accountDiff = {}) => {
	logger.log('[accountChanged] accountDiff: %o', accountDiff);

	let accountChanged =  (accountDiff.addressHash ||
	accountDiff.email ||
	accountDiff.phone ||
	accountDiff.suffix ||
	accountDiff.prefix ||
	accountDiff.firstName ||
	accountDiff.lastName ||
	accountDiff.birthYear > 0 || 
	accountDiff.ssn4 ||
  accountDiff.address1 ||
  accountDiff.address2 ||
  accountDiff.suiteName ||
  accountDiff.suiteNumber || 
  accountDiff.city ||
  accountDiff.stateProvince || 
  accountDiff.zipPostalCode ||
  accountDiff.zipPlus4)

	logger.log('[accountChanged] has data on the account changed: %o', accountChanged ? true : false);

	return accountChanged ? true : false
}

export const applicationChanged = (applicationDiff) => {
	
}

/** check if the Route param contains 'legacy' */
export const isLegacy = Route => {
	let isLegacy = false;
	let legacyParam = '';

	const legacyParams = ['legacy', 'legacyTickets', 'legacyPatron'];

	if(Route?.params) {
		for(let i=0; i < legacyParams.length; i++) {
			if(Route.params.hasOwnProperty(legacyParams[i])) {
				isLegacy = true;
				legacyParam = legacyParams[i];
				break;
			}
		}
	}

	return {isLegacy, legacyParam}
}

/** map legacy path to the new URL that go live in 2022 */
export  const getRedirectPath = (params, legacyParam) => {
	let url = '';
	let paramPath = params[legacyParam].toLowerCase();
	
	switch(legacyParam) {
		case 'legacy': {
			return url = '/en_US/index.html';
		}

		case 'legacyTickets': {

			if(paramPath.includes('info')) {
				return url = '/en_US/ticketsInfo';
			} else if (paramPath.includes('faq')) {
				return url = '/en_US/ticketsFAQ';
			} else if (paramPath.includes('policies')) {
				return url = '/en_US/ticketsPolicies';
			} else if (paramPath.includes('privacy')) {
				return url = '/en_US/info/privacy/index.html';
			} else {
				return url = '/en_US/ticketsInfo';
			}
		}

		case 'legacyPatron': {

			if(paramPath.includes('index_psb') || paramPath.includes('seriesbadges')) {
				return url = '/patron';
			} else if (paramPath.includes('faq')) {
				return url = '/patron/ticketsFAQ';
			} else if (paramPath.includes('policies')) {
				return url = '/patron/ticketsPolicies';
			} else if (paramPath.includes('juniorpass')) {
				return url = '/patron/juniorPass';
			} else {
				return url = '/patron';
			}
		}

		default:
			return url = '/en_US/index.html';
	}
}

export const openEmailWindow = (to, subject="", body=[]) => {
	logger.log('[openEmailWindow] - href: ', href);
	let line_break = '%0D%0A';

	let emailTo = to; //'toombsevents+1@gmail.com';
	let emailSub = subject; //'Request Account Deletion';
	for (var i = 0; i < body.length; i++) {
		body[i] = encodeURIComponent(body[i])
	}
	let emailBody = body.join(line_break);
	let href = `mailto:${emailTo}?subject=${emailSub}&body=${emailBody}`
		//window.open(`mailto:${emailTo}?subject=${emailSub}&body=${emailBody}`, '_blank');
	let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
	let iOS =
		/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	if (isSafari && iOS && href.indexOf('mailto') > -1) {
		window.location.href = href;
	} else {
		let win = window.open(
			href,
			'',
			'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
		);

		let checkBlank = function(openWin) {
			try {
				if (
					openWin.location.hostname !== undefined &&
					openWin.location.hostname === ''
				) {
					// means empty frame, can close
					openWin.close();
				}
			} catch (e) {
				// do nothing
			}
		};
		setTimeout(checkBlank.bind(this, win), 7000);
	}
}

export const isAddressEditable = (userData = {}, siteContent = {}, applicationData = {}) => {
	const allowAddressEdit = siteContent?.["system.allow_address_edit"] === "true";
	const changeOfAddressEnabled = siteContent?.["system.change_of_address_enabled"] === "true";
	const allowPaidAddressEdit = siteContent?.["system.allow_paid_address_edit"] === "true";
	const allowPayment = siteContent?.["system.allow_payment"] === "true"; 

	let isAddressEditable = allowAddressEdit;
	logger.log('[isAddressEditable] - applicationData:%o, allowPayment:%o', applicationData, allowPayment);

	//let applicationPhase = getApplicationPhase(userData?.application, siteContent);
	let isWinner = userData?.application?.isWinner;

	let isPaidWinner = userData?.application?.applicationStatus == 3;
	
	if (isWinner && !isPaidWinner) {
		Object.keys(applicationData).map(key => {
			let found = applicationData[key]?.applicationStatus == 3;
			//logger.log('[isAddressEditable] - found:%o', found);
			if (found) {
				isPaidWinner = true
			}
		})
	}
	
	logger.log('[isAddressEditable] - isPaidWinner:%o', isPaidWinner);

	if (userData?.addressValidationStatusId == 12) {
		logger.log('[isAddressEditable] - status 12');

		isAddressEditable = false;
	}
	else if (isWinner && !isPaidWinner && allowPayment && !changeOfAddressEnabled && !allowPaidAddressEdit) {
		logger.log('[isAddressEditable] - unpaid winner allow payment');
		isAddressEditable = false;
	}
	else if (isWinner && isPaidWinner && !allowPayment && !changeOfAddressEnabled && !allowPaidAddressEdit) {
		logger.log('[isAddressEditable] - paid winner, payments off, coa off, paid edit off');

		isAddressEditable = false;
	}

	// isAddressEditable =  (
	// 	userData?.addressValidationStatusId !== 12 &&
	// 	((eventData.allowAddressEdit && !isWinner) || 
	// 	(eventData.allowAddressEdit && eventData.changeOfAddressEnabled && isWinner)))
	logger.log('[isAddressEditable] - isAddressEditable:%o', isAddressEditable);

	return isAddressEditable;
}

export const isPaidWinner = (userData = {}, applicationData = {}) => {
	let isWinner = userData?.application?.isWinner;

	let isPaidWinner = userData?.application?.applicationStatus == 3;
	
	if (isWinner && !isPaidWinner) {
		Object.keys(applicationData).map(key => {
			let found = applicationData[key]?.applicationStatus == 3;
			//logger.log('[isPaidWinner] - found:%o', found);
			if (found) {
				isPaidWinner = true
			}
		})
	}
	logger.log('[isPaidWinner] - isPaidWinner:%o', isPaidWinner);

	return isPaidWinner;
}

export const shouldMobileVerify = (requiresVerifyId = {}, systemVerify = "false", userCountry = '') => {
	logger.log('[shouldMobileVerify] - systemVerify && requiresVerifyId:%o', systemVerify && requiresVerifyId?.[userCountry]);

	return systemVerify === "true" && requiresVerifyId?.[userCountry]
}

export const getPaymentSteps = (userData = {}, systemVerify = "false", eventData = {}, requiresVerifyId = {}) => {
	logger.log('[getPaymentSteps] - userData:%o, systemVerify:%o, eventData:%o, requiresVerifyId:%o', userData, systemVerify, eventData, requiresVerifyId);

	let application = userData?.application;
	let applicationPhase = getApplicationPhase(application, eventData);

	let currentStep = 2;
	let stepsConfig = {
		"payment.steps_title": "Review, Terms, Verify, Payment, Confirm",
		"payment.steps_title_international": "Review, Terms, Payment, Confirm",
		"payment.steps_title_free": "Review, Terms, Verify, Confirm",
		"payment.steps_title_free_international": "Review, Terms, Confirm",
		"payment.steps_title_pcb": "Review, Terms, Verify, Payment, Confirm",
		"payment.steps_title_pcb_international": "Review, Terms, Payment, Confirm",
		"payment.steps_title_noverify" : "Review, Terms, Payment, Confirm",
		"payment.steps_title_free_noverify" : "Review, Terms, Payment, Confirm"
	}
	let steps = [];
	let stepsId = 'payment.steps_title';
	let mobileVerify = shouldMobileVerify(requiresVerifyId, systemVerify, userData?.country);

	// verify turned on
	if (mobileVerify) {
		logger.log('[getPaymentSteps] - mobileVerify:%o', mobileVerify);

		if (eventData?.eventName !== 'pcb') {
			// check if free tickets
			if (applicationPhase == 'applicationWinner') {
				stepsId = application?.amtDue > 0
				? 'payment.steps_title'
				: 'payment.steps_title_free'
			} 
			else if (applicationPhase == 'applicationPaid') {
				stepsId = application?.amtPaid > 0
				? 'payment.steps_title'
				: 'payment.steps_title_free'
			}
			
		} 
		// pcb
		else {
			stepsId = 'payment.steps_title_pcb'
		}
	}
	// verify is turned off
	else {
		if (applicationPhase == 'applicationWinner') {
			stepsId = application?.amtDue > 0 
			? 'payment.steps_title_international'
			: 'payment.steps_title_free_international'
		} else if (applicationPhase == 'applicationPaid') {
			stepsId = application?.amtPaid > 0 
			? 'payment.steps_title_international'
			: 'payment.steps_title_free_international'
		}
		
	}

	steps = stepsConfig?.[stepsId]?.split(',');
	
	if (applicationPhase == "applicationPaid") {
		currentStep = steps?.length;
	} else if (applicationPhase == "applicationWinner") {
		logger.log('[getPaymentSteps] - applicationPhase:%o', applicationPhase);

		if (!application?.hasAcceptedTicketAgreement) {
			currentStep = 1;
		} else {
			if (mobileVerify) {
				currentStep++;
				if (userData?.datePhoneVerified && eventData?.allowPayment) {
					currentStep++;
				} 
				else if (!eventData.allowPayment) {
					currentStep = 1;
				} 
			} else {
				currentStep++;
			}
		}
	}
	//let page = (mobileVerify && userData?.addressValidationStatusId !== 12) ? steps[currentStep - 1]?.trim() : steps[0];
	let page = (userData?.addressValidationStatusId !== 12) ? steps[currentStep - 1]?.trim() : steps[0];

	logger.log('[getPaymentSteps] - return:%o', {"id":stepsId, currentStep, page});
	return {"id":stepsId, currentStep, page}
}

export const fetchDataFromPaths = async (dataPaths = {}, token) => {
	logger.log('[fetchDataFromPaths] - dataPaths:%o', dataPaths);
  
	const dataObject = {};
	try {
	  const fetchPromises = Object.entries(dataPaths).map(async ([dataName, path]) => {
		try {
		  const response = await axios({
			method: 'get',
			url: path,
			headers: { Authorization: `Bearer ${token}` },
		  });
  
		  if (response.status === 200) {
			dataObject[dataName] = response.data;
		  } else {
			// Handle other HTTP statuses
			throw new Error(`Failed to fetch data for ${dataName}. Status: ${response.status}`);
		  }
		} catch (error) {
		  if (axios.isCancel(error)) {
			// Axios cancellation
			throw error;
		  } else {
			// Handle other errors
			throw error;
		  }
		}
	  });
  
	  await Promise.all(fetchPromises);
	} catch (error) {
	  // Handle any error that might occur outside of Axios requests
	  console.error('[fetchDataFromPaths] - Error:', error);
	}
  
	logger.log('[fetchDataFromPaths] - dataObject:%o', dataObject);
	return dataObject;
};

export const restHeaders = () => {
	return {};
};

export const fetch = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			//logger.log('[Shared Util] fetch - error:%o', error);
			if (error.response && error.response.status == 404) {
				//logger.log('[Shared Util] fetch - error1:%o', error.response);
				throw error.response;
			} else {
				//logger.log('[Shared Util] fetch - error2:%o', error);
				throw error;
			}
		});
};

export const fetchAll = (paths) => {
	let hdr = restHeaders();
	let pathList = [];
	const pathsType = typeof paths;
	logger.log('[Shared Util] fetch - paths:%o', paths);
	
	if (Array.isArray(paths)) {
		pathList = paths
	} else {
		return new Error("Invalid type for paths");
	}
	logger.log('[Shared Util] fetchAll - pathList:%o', pathList);

	return axios.all(pathList.map(path => fetch(path, { headers: hdr }))).then(function(results) {
		logger.log('[Shared Util] fetchAll - results:%o', results);

		const result = results.map(response => {
			logger.log('[Shared Util] fetchAll - 200 valid response:%o', response);
			return response;
		});

		logger.log('[Shared Util] fetchAll - result:%o', result);

		let data = Array.isArray(paths) ? result : Object.assign({},...result);
		logger.log('[Shared Util] fetchAll - return data:%o', data);

		return data;
	}).catch(error => {
		logger.log('[Shared Util] fetchAll - error:%o', error);
		throw error;
	});
};

/** create a new promise to use in fetchPromiseAll() */
export const fetchNewPromise = (path) => {
	logger.log('[Shared Util] fetchPromise path:%o', path);
	return new Promise((resolve, reject) =>{
		try{
			fetch(path)
				.then(result => {
					logger.log('[Shared Util] fetchPromise result:%o', result);
					return resolve(result);
				})
				.catch(error => {
					logger.error('[Shared Util] fetchPromise error:%o', error);
					// return resolve('error');
					return resolve({ status: 'error', error });
				});
		} catch(err) {
			logger.error('[Shared Util] fetchPromise error:%o', err);
			// return resolve('error');
			return resolve({ status: 'error', err });
		}
	});
}

/** gets an array of paths and create a new Promise for each and perform Promise.all */
export const fetchPromiseAll = (paths) => {
	let pathList = [];
	if (Array.isArray(paths)) {
		pathList = paths
	} else {
		return new Error("Invalid type for paths");
	}

	return Promise.all(pathList.map(path => fetchNewPromise(path)))
}