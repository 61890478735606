// awt:  removing devtools.  I don't think we need this and can use chrome devtools extension
//import DevTools from 'dotix/components/DevTools';

export default (enhancers = [], isServer = false) => {
	return [
		// {
		// 	name: 'devtools',
		// 	order: -1000,
		// 	enhancer: process.env.NODE_ENV === 'development' ? DevTools.instrument() : _ => _,
		// },
		...enhancers,
	];
};
