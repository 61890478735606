/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import deps from 'dependencies';
 import op from 'object-path';
 import { Redirect } from 'react-router';
  
 import { Formik, Form } from 'formik';
 import * as yup from 'yup';
 import { writeErrors } from 'shared/forms/elements/Utils';
 import { FormInput, FormSelect, FormEmail } from 'shared/forms/elements/FormFields';
 import Button from 'shared/general/Button';
 import EventsLink from 'shared/general/EventsLink';
 
 import isEqual from 'lodash/isEqual';
 
 import LoadingIndicator from 'shared/general/LoadingIndicator';
 import { isEmpty } from 'lodash';
 import AccountInfo from '../../general/AccountInfo';
 import { getApplicationPhase, difference, isAddressEditable, fetchDataFromPaths } from 'shared/general/Util';

 import MeasurementUtils from 'appdir/lib/analytics';

 import axios from 'axios';

 /**
  * -----------------------------------------------------------------------------
  * Functional Component: const AccountProfileForm = (props) => {
  * -----------------------------------------------------------------------------
  */
  const mapStateToProps = (state, props) => {
     return {
         ...props,
         Router: state['Router'],
         cmsMessages: state['Config'].staticData?.cmsMessages?.data,
         tempUserData: state['UserData']?.tempUserData,
         site: state?.['Config']?.dataSettings?.site,
         pendingEmail: state['UserData']?.pendingEmail,
         ticketsData: state?.['Config']?.ticketsData,
         addressValidation: state?.['Config']?.addressValidation,
     };
 };
 
 const mapDispatchToProps = dispatch => ({
     updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data))
 });

 const shouldValidate = (formDiff={}, userData={}) => {
    let validate = false
    
    Object.keys(formDiff).map(key => {
            if (
                key !== 'email' &&
                key !== 'firstName' && 
                key !== 'lastName' && 
                key !== 'phone' && 
                key !== 'prefix' && 
                key !== 'suffix'
            ) {
                validate = true
            }
    })
    logger.log('[AccountProfileForm] shouldValidate validate:%o', validate);
    return userData?.addressValidationStatusId == 2 || validate;
 }
 
 class AccountProfileForm extends Component {
     constructor(props) {
         super(props);
         this.state = {
             success: false,
             successURL: null,
             userData: {},
             errors: {},
             mounted: false
         };
 
         this.linkPrefix = window.webview ? '/webview' : '';
         this.loadingRef = React.createRef();
         this.isPhoneValid = false;
         this.submit = false;
         this.handleCancel = this.handleCancel.bind(this);
         this.formDiff = {};
         logger.log('[AccountProfileForm] props:%o', props);
     }

     componentDidMount() {
       fetchDataFromPaths(this?.props?.ticketsData?.applicationUrlList, this?.props?.userData?.token)
       .then((result) => {
            this.setState({
                mounted: true,
                applicationData: result,
                editable: isAddressEditable(this?.props?.userData, this?.props?.cmsMessages, result)
            })
       })
       .catch((error) => {
            this.setState({
                mounted: true
            })
       })

        
     }
 
     componentWillUnmount() {
         this.submit = false;
     }

     getSuccessURL() {
        let successURL = ''
        if (this.state.editable && shouldValidate(this.accountDiff), this?.props?.tempUserData) {
            successURL =  this.props.flowData.actions.accountProfile.next
        } else if (!this.props.userData.eventName == 'psb' && this.state.editable && (this.props.tempUserData.birthYear === 0 || !this.props.tempUserData.birthYear)) {
            successURL =  this.props.flowData.actions.accountProfile.pin
        } else {
            successURL =  this.props.flowData.actions.accountProfile.review
        }

        logger.log('[AccountProfileForm] getSuccessURL :%o', successURL);
        return successURL;
     }
 
     componentDidUpdate(prevProps, prevState) {
         logger.log('[AccountProfileForm] componentDidUpdate prevState:%o, prevProps:%o, this:%o', prevState, prevProps, this);
         
         if((!isEqual(prevProps.userData, this.props.userData) || !isEqual(prevProps.pendingEmail, this.props.pendingEmail) || !isEmpty(this.accountDiff)) && 
         this.submit === true && !isEmpty(this.props.flowData)) {
             this.submit = false;
 
             if (this.loadingRef.current) {
                 this.loadingRef.current.classList.remove('show');
             }
 
            logger.log('[AccountProfileForm] componentDidUpdate formDiff:%o', this.formDiff);
            logger.log('[AccountProfileForm] componentDidUpdate - shouldValidate:%o', shouldValidate(this.formDiff));
            logger.log('[AccountProfileForm] componentDidUpdate accountDiff:%o', this.accountDiff);
            logger.log('[AccountProfileForm] componentDidUpdate - shouldValidate:%o', shouldValidate(this.accountDiff, this?.props?.tempUserData));

            let successURL = this.getSuccessURL();
             this.setState({
                 success: true,
                 successURL,
                 errors: {}
             }, () => {
                MeasurementUtils.dispatchMeasurementCall('AccountProfile', {
                    action: 'next',
                    site: this.props.site,
                    userID: this.props.tempUserData.userID,
                });
             });
         }
     }
 
     handleAutoFill(e) {
         logger.log('[AccountProfileForm] handleAutofill - e:%o', e.currentTarget);
 
         this.setState({
             [e.target.name]: {
                 ...this.state[e.target.name],
                 active: e.animationName === 'onAutoFillStart' ? true : false,
                 autofill: e.animationName === 'onAutoFillStart' ? true : false,
             },
         });
     }
 
     handleFocus(e, val, formProps) {
         logger.log('[AccountProfileForm] handleFocus - e:%o, val:%o, formProps:%o', e.target.name, val, formProps);
         logger.log('[AccountProfileForm] handleFocus - state1:%o', this.state.email);

         if (formProps) {
            formProps.handleBlur(e);
         }

        if (e.target.value === '') {
             this.setState({
                 [e.target.name]: {
                     ...this.state[e.target.name],
                     focus: val,
                 },
             });
         } else {
             this.setState({
                 [e.target.name]: {
                     ...this.state[e.target.name],
                     focus: true,
                 },
             });
         }
     }

     handleCancel() {
        MeasurementUtils.dispatchMeasurementCall('AccountProfile', {
            action: 'cancel',
            site: this.props.site,
            userID: this.props.tempUserData.userID,
        });
     }
 
     handleSubmit = (values) => {
         logger.log('[AccountProfileForm] handleSubmit - this:%o, values:%o', this, values);
         this.formDiff = difference(this._initialValues, values);
         /** show loading indicator while everyone is working hard to create an account */
         if (this.loadingRef.current) {
             // this.loadingRef.current.classList.add('show');
         }
 
         let userData = {
             prefix: op.get(values, 'prefix.label', ''),
             firstName: values.firstName.replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"'),
             lastName: values.lastName.replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"'),
             email: values.email,
             suffix: op.get(values, 'suffix.label', ''),
             country: op.get(values, 'country.value', ''),
             //countryCode: op.get(values, 'country.value', ''),
             //countryName: op.get(values, 'country.label', ''),
             address1: values.address1,
             address2: values.address2,
             //suiteName: op.get(values, 'suiteName.label', ''),
             suiteName: op.get(values, 'suiteName.value', ''),
             suiteNumber: values.suiteNumber,
             city: values.city,
             stateProvince: values.stateProvince,
             zipPostalCode: values.zipPostalCode,
             zipPlus4: values.zipPlus4,
             phone: values.phone,
         }

         this.accountDiff = difference(this.props.tempUserData, userData);

         logger.log('[AccountProfileForm] handleSubmit - difference this.formDiff:%o, userData:%o', this.formDiff, userData);
         logger.log('[AccountProfileForm] handleSubmit -difference accountDiff:%o, userData:%o', this.accountDiff, userData);

         if (this.accountDiff.email && this.props.tempUserData.email.toLowerCase() !== userData.email.toLowerCase()) {
            this.handleInitiateEmailChange(userData)
         } 
             // the form changed (case insensitive)
         else if (!isEmpty(this.formDiff) || !isEmpty(this.accountDiff)) { 
          //if (Object.keys(this.formDiff).length > 1 || (Object.keys(this.formDiff).length == 1 && !this.formDiff.email)) {
            logger.log('[AccountProfileForm] handleSubmit formDiff not empty. updateUserData and this.submit is true');

            this.submit = true;

            // if the changed form is differnt from what's in db (tempUserData) then replace
            // redux userData with tempUserData.  Otherwise update redux userData with the new changes
            // for db upate later in the flow
            if (isEmpty(this.accountDiff)) {
                logger.log('[AccountProfileForm] handleSubmit accountDiff empty');
                this.props.updateUserData({...this.props.tempUserData, pendingSubmitted:false});
            } else {
                logger.log('[AccountProfileForm] handleSubmit form changed');
                this.props.updateUserData({...userData, pendingSubmitted: false});
            }
         } else {
            // the form did not change (ie user made no changes and hit next) 
            logger.log('[AccountProfileForm] handleSubmit form not changed');

            if (op.get(this.props, 'Router.historyArray')) {
                // logger.log('[CancelRegistration] componentDidUpdate - historyArray:%o', this.props.Router.historyArray);

                let found;
    
                let temp = this.props.Router.historyArray.reverse();
    
                found = temp.find(function(el) {
                    // if user is editing address from the a review screen then we ultimately want to go back
                    // to that previous screen.  if not, then i think this will be coming from like the suppl privacy
                    // accept screen.

                    if (el.indexOf('/review') !== -1) {
                        return el.indexOf('/update') === -1;
                    } else if (el.indexOf('/payment/complete') !== -1) {
                        return el.indexOf('/update') === -1;
                    } else {
                        return false;
                    }
                });
    
                logger.log('[AccountProfileForm] componentDidUpdate - historyArray reversed:%o, found:%o', temp, found);

                if (found) {
                    this.setState({
                        success: true,
                        successURL: found,
                        errors: {}
                    }, () => {
                        logger.log('[AccountProfileForm] handleSubmit found - this:%o', this);
                    });
                } else {
                    this.submit = true;
                    this.props.updateUserData({...userData, pendingSubmitted: false});
                    logger.log('[AccountProfileForm] handleSubmit not found this:%o', this);

                }
            } else {
                logger.log('[AccountProfileForm] handleSubmit no router this:%o', this);

            }
         }
     }

     handleInitiateEmailChange = (userData) => {
        let url = this.props.ticketsData.initiateEmailChange;

		axios({
            method: 'post',
            url: `${url}`,
            data: {
                email: userData.email,
                provider: this.props.ticketsData.provider,
            },
            headers: { Authorization: `Bearer ${this?.props?.userData?.token}` },
        })
            .then(resp => {
                logger.log('[AccountProfileForm] handleInitiateEmailChange - resp:%o', resp);
                if (resp.status == 200) {
                    this.submit = true;
                    this.props.updateUserData({...userData, pendingSubmitted: false});
                    this.setState({
                        ...this.state,
                        errors: {}
                    });
                } else {
                    this.setState({
                        success: false,
                        errors: {
                            response:this?.props?.cmsMessages["account.email_confirm_failure"]
                        }
                    });
                }
            })
            .catch(e => {
                logger.log('[Confirmation] handleInitiateEmailChange - Error:%o', e.response);
                this.setState({
                    success: false,
                    errors: {
                        response: e.response.status == 400 ? this?.props?.cmsMessages["login.create.account_exists"] : this?.props?.cmsMessages["account.email_confirm_failure"]
                    }
                });
            });
    }
 
     getLoadingIndicator = () => {
         return (
             <div className="fadeOverlay" ref={this.loadingRef}>
                 <LoadingIndicator />
             </div>
         );
     };

     isNameEditable() {
         if(this.props.userData.addressHash || (this.props.userData.eventName == 'psb' && this.props.userData.addressHash == '')) {
             return true
         }else{
            return false
         }     
     }
  
     render() {
         logger.log('[AccountProfileForm] render - this:%o', this);
         let applicationPhase = getApplicationPhase(this.props.userData.application, this.props.eventConfig);

         if (this.state.success === true && this.state.successURL) {
             return (
                <Redirect push to={{
                    pathname:this.state.successURL,
                    //state: {shouldValidate: shouldValidate(this.formDiff)}
                    state: {shouldValidate: shouldValidate(this.accountDiff, this?.props?.tempUserData)}
                    }} />
             );
         }
 
         if(!isEmpty(this.props.flowData) && this?.state?.mounted) {
             let { userData } = this.props;
             let _this = this;
 
             this.AccountProfileValues = {
                 prefix: this?.props?.prefixProps?.initialValue ?? '',
                 firstName: userData?.firstName ?? '',
                 lastName: userData?.lastName ?? '',
                 email: userData?.email ?? '',
                 suffix: this?.props?.suffixProps?.initialValue ?? '',
                 country: this?.props?.countryProps?.initialValue ?? '',
                 address1: userData?.address1 ?? '',
                 address2: userData?.address2 ?? '',
                 //suiteName: op.get(userData, 'suiteName', false) ? userData.suiteName : '',
                 suiteName: this?.props?.suiteNameProps?.initialValue ?? '',
                 suiteNumber:  userData?.suiteNumber ?? '',
                 city: userData?.city ?? '',
                 stateProvince: userData?.stateProvince ?? '',
                 zipPostalCode: userData?.zipPostalCode ?? '',
                 zipPlus4: userData?.zipPlus4 ?? '',
                 phone: userData?.phone ?? ''
             };
 
             let AccountProfileSchema = yup.object({
                 firstName: yup.string().required('First Name is required'),
                 lastName: yup.string().required('Last Name is required'),
                 country: yup.object().required('Please select a Country'),
                 address1: yup.string().required('Address is required'),
                 address2: yup.string(),
                 city: yup.string().required('City is required'),
                 stateProvince: yup.string().when('country', {
                    is:(option) => {
                        logger.log('[AccountProfileForm] validate state - option:%o', option);
                        return this?.props?.addressValidation?.requiresState?.includes(option.value) || this?.props?.addressValidation?.requiresState?.length == 0
                    },
                    then: () => (yup.string().required('State is required')),
                    otherwise: () => {yup.string()}
                 }),
                 suiteNumber:yup.string().test('suiteNumber', `Please enter an Apt/Suite number`, function() {
                     let validSuite = 
                     this?.parent?.suiteNumber
                     || (!this?.parent?.suiteName?.value) 
                     || (this?.parent?.suiteName?.value && this?.parent?.suiteNumber) 
                     ? true 
                     : false
                     return validSuite;
                 }),
                 zipPostalCode: yup
                     .string()
                     .required('Postal Code is required')
                     .max(15, 'Postal Code can not be longer than 15 characters'),
                 phone: yup
                     .string()
                     .required('Mobile Phone is required')
                     .test('phone', 'Please enter a valid Mobile Phone', function(value) {
                         let phoneRegex = new RegExp(_this.props.ticketsData.phoneRegex);
                         let country = this.parent.country.value;
                         let validPhone = false;
 
                         // logger.log('[AccountProfileForm] render - value:%o', value);
                         // logger.log('[AccountProfileForm] render - country:%o', country);
                        
                         validPhone = phoneRegex.test(value);
                         return validPhone;
                     }),
                email: yup
                     .string()
                     .email('Please supply a valid Email Address')
                     .required('Email Address is required'),
             });
 
             return (
                 <>
                 {/* awt:  moved to the AccountProfile page */}
                 {/* {op.get(this.props, 'Router.state.errors.response', false) || op.get(this.props, 'errors.response') ?
                     <div className="return-error">Write error message here</div>
                     :
                     null
                 } */}
 
                 {/* Show account info if address editing is turned off */}
                 {
                     !this.state.editable ? (
                         <AccountInfo
                             category="addressDisabled"
                             userData={this.props.userData}
                             editable={this.state.editable}
                         />
                        )
                        : null
                 }
                 <div className="form-container account-information">
                     <div className="form-content">
                         <div className="account-information-container">                            
                             <Formik
                                 initialValues={this.AccountProfileValues}
                                 validationSchema={AccountProfileSchema}
                                 onSubmit={this.handleSubmit}
                                 enableReinitialize={true}
                                 validateOnChange={true}
                                 validateOnBlur={false}
                                 key="accountProfile">
                                 {formProps => {
                                     logger.log('[AccountProfileForm] render - formProps:%o', formProps);
                                     this._initialValues = formProps.initialValues;
                                     return (
                                         <Form id="accountProfileForm">
                                             {writeErrors(formProps, this.state.errors)}
                                             {
                                                 this.state.editable
                                                 ? (<>
                                                         <div className="two-columns">
                                                                <FormSelect
                                                                    label="Prefix"
                                                                    labeltype="fixed"
                                                                    name="prefix"
                                                                    placeholder="Prefix"
                                                                    options={this.props.prefixProps.options}
                                                                    isClearable={true}
                                                                    onMenuOpen={() => {
                                                                        this.props.onMenuOpen('prefix_open', true);
                                                                    }}
                                                                    onMenuClose={() => {
                                                                        this.props.onMenuOpen('prefix_open', false);
                                                                    }}
                                                                    onChange={option => {
                                                                        formProps.setFieldValue('prefix', option);
                                                                    }}
                                                                    class={this.props.prefixProps.class}
                                                                />
                                                            {
                                                                !this.isNameEditable() ? (
                                                                <FormInput
                                                                    label="First Name"
                                                                    labeltype="float"
                                                                    name="firstName"
                                                                    type="text"
                                                                    maxLength="50"
                                                                    required={true}
                                                                    onAnimationStart={e => this.handleAutoFill(e)}
                                                                    onAnimationEnd={e =>
                                                                        e.animationName === 'onAutoFillStart'
                                                                            ? (formProps.touched.firstName = true)
                                                                            : (formProps.touched.firstName = false)
                                                                    }
                                                                    onFocus={e => this.handleFocus(e, true)}
                                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                                />
                                                            ): <FormInput 
                                                                    className="disabled-input"
                                                                    label="First Name"
                                                                    labeltype="float"
                                                                    name="firstName"
                                                                    type="text"
                                                                    disabled={this.isNameEditable()}
                                                                />
                                                            }
                                                         </div>         
                                                         <div className="two-columns">
                                                            {
                                                                !this.isNameEditable() ? (  
                                                                <FormInput
                                                                    label="Last Name"
                                                                    labeltype="float"
                                                                    name="lastName"
                                                                    type="text"
                                                                    maxLength="50"
                                                                    required={true}
                                                                    onAnimationStart={e => this.handleAutoFill(e)}
                                                                    onAnimationEnd={e =>
                                                                        e.animationName === 'onAutoFillStart'
                                                                            ? (formProps.touched.lastName = true)
                                                                            : (formProps.touched.lastName = false)
                                                                    }
                                                                    onFocus={e => this.handleFocus(e, true)}
                                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                                    disabled={this.isNameEditable()}
                                                                />
                                                            ): <FormInput
                                                                    className="disabled-input"
                                                                    label="Last Name"
                                                                    labeltype="float"
                                                                    name="lastName"
                                                                    type="text"
                                                                    disabled={this.isNameEditable()}
                                                                />
                                                            }
                                                                   
                                                             <FormSelect
                                                                 label="Suffix"
                                                                 labeltype="fixed"
                                                                 name="suffix"
                                                                 placeholder="Suffix"
                                                                 options={this.props.suffixProps.options}
                                                                 isClearable={true}
                                                                 onMenuOpen={() => {
                                                                     this.props.onMenuOpen('suffix_open', true);
                                                                 }}
                                                                 onMenuClose={() => {
                                                                     this.props.onMenuOpen('suffix_open', false);
                                                                 }}
                                                                 onChange={option => {
                                                                     formProps.setFieldValue('suffix', option);
                                                                 }}
                                                                 class={this.props.suffixProps.class}
                                                             />
                                                         </div>
 
                                                         <div className="two-columns">
                                                             <FormSelect
                                                                 label="Country"
                                                                 labeltype="fixed"
                                                                 name="country"
                                                                 placeholder="Select Country"
                                                                 options={this.props.countryProps.options}
                                                                 isClearable={false}
                                                                 onMenuOpen={() => {
                                                                     this.props.onMenuOpen('country_open', true);
                                                                 }}
                                                                 onMenuClose={() => {
                                                                     this.props.onMenuOpen('country_open', false);
                                                                 }}
                                                                 onChange={option => {
                                                                     formProps.setFieldValue('country', option);
                                                                 }}
                                                                 class={this.props.countryProps.class}
                                                             />
                                                         </div>
 
                                                         <FormInput
                                                             label="Address"
                                                             labeltype="float"
                                                             name="address1"
                                                             type="text"
                                                             maxLength="50"
                                                             required={true}
                                                             onAnimationStart={e => this.handleAutoFill(e)}
                                                             onAnimationEnd={e =>
                                                                 e.animationName === 'onAutoFillStart'
                                                                     ? (formProps.touched.address1 = true)
                                                                     : (formProps.touched.address1 = false)
                                                             }
                                                             onFocus={e => this.handleFocus(e, true)}
                                                             onBlur={e => this.handleFocus(e, false, formProps)}
                                                         />
                                                        {
                                                            this?.props?.addressValidation?.useAddress2?.includes(formProps.values["country"].value) || this?.props?.addressValidation?.useAddress2?.length == 0 ? (
                                                                <FormInput
                                                                    label="Address - Line 2"
                                                                    labeltype="float"
                                                                    name="address2"
                                                                    type="text"
                                                                    maxLength="50"
                                                                    onAnimationStart={e => this.handleAutoFill(e)}
                                                                    onAnimationEnd={e =>
                                                                        e.animationName === 'onAutoFillStart'
                                                                            ? (formProps.touched.address2 = true)
                                                                            : (formProps.touched.address2 = false)
                                                                    }
                                                                    onFocus={e => this.handleFocus(e, true)}
                                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                                />
                                                            )
                                                            : null
                                                        }
                                                        
                                                         <div className="two-columns">
                                                             <FormSelect
                                                                 label="Apt/Suite"
                                                                 labeltype="fixed"
                                                                 placeholder="Apt/Suite"
                                                                 name="suiteName"
                                                                 options={this.props.suiteNameProps.options}
                                                                 isClearable={true}
                                                                 onMenuOpen={() => {
                                                                     this.props.onMenuOpen('suiteName_open', true);
                                                                 }}
                                                                 onMenuClose={() => {
                                                                     this.props.onMenuOpen('suiteName_open', false);
                                                                 }}
                                                                 onChange={option => {
                                                                     formProps.setFieldValue('suiteName', option);
                                                                     document.getElementById("suiteNumber").focus();
                                                                 }}
                                                                 class={this.props.suiteNameProps.class}
                                                             />
 
                                                             <FormInput                                
                                                                 id="suiteNumber"
                                                                 label="Number"
                                                                 labeltype="float"
                                                                 name="suiteNumber"
                                                                 type="text"
                                                                 maxLength="10"
                                                                 onAnimationStart={e => this.handleAutoFill(e)}
                                                                 onAnimationEnd={e =>
                                                                     e.animationName === 'onAutoFillStart'
                                                                         ? (formProps.touched.suiteNumber = true)
                                                                         : (formProps.touched.suiteNumber = false)
                                                                 }
                                                                 onFocus={e => this.handleFocus(e, true)}
                                                                 onBlur={e => this.handleFocus(e, false, formProps)}
                                                             />
                                                         </div>
 
                                                         <div className="four-columns">
                                                             <FormInput
                                                                 label="City"
                                                                 labeltype="float"
                                                                 name="city"
                                                                 type="text"
                                                                 maxLength="35"
                                                                 required={true}
                                                                 onAnimationStart={e => this.handleAutoFill(e)}
                                                                 onAnimationEnd={e =>
                                                                     e.animationName === 'onAutoFillStart'
                                                                         ? (formProps.touched.city = true)
                                                                         : (formProps.touched.city = false)
                                                                 }
                                                                 onFocus={e => this.handleFocus(e, true)}
                                                                 onBlur={e => this.handleFocus(e, false, formProps)}
                                                             />
 
                                                             <FormInput
                                                                 label="State/Province"
                                                                 labeltype="float"
                                                                 name="stateProvince"
                                                                 type="text"
                                                                 maxLength="25"
                                                                 required={this?.props?.addressValidation?.requiresState?.includes(formProps.values["country"].value) || this?.props?.addressValidation?.requiresState?.length == 0}
                                                                 onAnimationStart={e => this.handleAutoFill(e)}
                                                                 onAnimationEnd={e =>
                                                                     e.animationName === 'onAutoFillStart'
                                                                         ? (formProps.touched.state = true)
                                                                         : (formProps.touched.state = false)
                                                                 }
                                                                 onFocus={e => this.handleFocus(e, true)}
                                                                 onBlur={e => this.handleFocus(e, false, formProps)}
                                                             />
                                                         
                                                             <FormInput
                                                                 label="Zip/Postal"
                                                                 labeltype="float"
                                                                 name="zipPostalCode"
                                                                 type="text"
                                                                 maxLength="15"
                                                                 required={true}
                                                                 onAnimationStart={e => this.handleAutoFill(e)}
                                                                 onAnimationEnd={e =>
                                                                     e.animationName === 'onAutoFillStart'
                                                                         ? (formProps.touched.zipPostalCode = true)
                                                                         : (formProps.touched.zipPostalCode = false)
                                                                 }
                                                                 onFocus={e => this.handleFocus(e, true)}
                                                                 onBlur={e => this.handleFocus(e, false, formProps)}
                                                             />
 
                                                             <FormInput
                                                                 label="Zip + 4"
                                                                 labeltype="float"
                                                                 name="zipPlus4"
                                                                 type="text"
                                                                 maxLength="4"
                                                                 onAnimationStart={e => this.handleAutoFill(e)}
                                                                 onAnimationEnd={e =>
                                                                     e.animationName === 'onAutoFillStart'
                                                                         ? (formProps.touched.zipPlus4 = true)
                                                                         : (formProps.touched.zipPlus4 = false)
                                                                 }
                                                                 onFocus={e => this.handleFocus(e, true)}
                                                                 onBlur={e => this.handleFocus(e, false, formProps)}
                                                             />
                                                         </div>
                                                     </>)
                                                 : null
                                             }
                                            <div className="two-columns">
                                                <FormInput
                                                    label="Mobile Phone"
                                                    labeltype="float"
                                                    name="phone"
                                                    type="text"
                                                    maxLength="30"
                                                    required={true}
                                                    onAnimationStart={e => this.handleAutoFill(e)}
                                                    onAnimationEnd={e =>
                                                        e.animationName === 'onAutoFillStart'
                                                            ? (formProps.touched.phone = true)
                                                            : (formProps.touched.phone = false)
                                                    }
                                                    onFocus={e => this.handleFocus(e, true)}
                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                />
                                        
                                                <FormInput
                                                    label="Email"
                                                    labeltype="float"
                                                    name="email"
                                                    type="email"
                                                    maxLength="256"
                                                    required={true}
                                                    instructions={this.state?.email?.focus == true ? "true" : "false"}
                                                    instructions-text={this?.props?.cmsMessages['account.email_verify']}
                                                    onAnimationStart={e => this.handleAutoFill(e)}
                                                    onAnimationEnd={e =>
                                                        e.animationName === 'onAutoFillStart'
                                                            ? (formProps.touched.email = true)
                                                            : (formProps.touched.email = false)
                                                    }
                                                    onFocus={e => this.handleFocus(e, true, formProps)}
                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                />
                                            </div>
                                             <div className="page-actions">
                                                 <Button
                                                     style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                     buttonState={!formProps.isValid ? 'disabled' : ''}
                                                     onClick={() => {
                                                         if (formProps.isValid) {
                                                             formProps.submitForm();
                                                         }
                                                     }}
                                                     buttonText="Next" />
                                                 <div className="action-link-container">
                                                     <EventsLink onClick={() => this.handleCancel()} style="action-link" to={this.props.flowData.actions.accountProfile.cancel}>
                                                         Cancel
                                                     </EventsLink>
                                                 </div>
                                             </div>
                                         </Form>
                                     )
                                 }}
                             </Formik>
                         </div>
                     </div>
                     {this.getLoadingIndicator()}
                 </div>
                 </>
             );
         } else {
            return (
                <>
                    <section className='page-grid cols-1' style={{"minHeight":"100px"}}>
                        {this.getLoadingIndicator()}
                    </section>
                </>
             ); 
         }
     }
 };
  
 export default connect(mapStateToProps, mapDispatchToProps)(AccountProfileForm);
  