/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Fragment } from 'react';
import ReactHtmlParser from 'html-react-parser';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: StubMessage
 * -----------------------------------------------------------------------------
 */
const StubBox = (props) => {
    let { attributes } = props;
    let klass = attributes.klass ? attributes.klass : '';

    logger.log('[StubBox]  - attributes: %o, klass:%o', attributes, klass);

    return (
            attributes.message  ?
                <Fragment>
                    <section className={`page-content stub ${klass}`}>
						<section className="page-grid cols-1">
							<div className="hero">
								<div className="stub_message">
                                    {
                                        (attributes.message && attributes.stubdate) ?
                                        (<Fragment>
                                            <div className="stub_date">{ReactHtmlParser(attributes.stubdate)}</div>
                                            <div className="stub_title">{ReactHtmlParser(attributes.message)}</div>
                                        </Fragment>)
                                        : <div className="stub_title">{ReactHtmlParser(attributes.message)}</div>
                                    }
								</div>
							</div>
						</section>
					</section>
                </Fragment>            
            : null
    );
};

export default StubBox;