/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, {useState, useEffect} from 'react';
 import op from 'object-path';
 import JSXParser from 'react-jsx-parser';
 import EventsLink from 'shared/general/EventsLink';
 import CmsMessageContent from 'shared/general/CmsMessageContent';
 import { useSelector } from 'react-redux';
 import ErrorBoundary from 'shared/general/ErrorBoundary';
 import { getApplicationPhase, isAddressEditable, fetchDataFromPaths } from 'shared/general/Util';

 import axios from 'axios';

 /**
  * -----------------------------------------------------------------------------
  * React Component: Account Information
  * -----------------------------------------------------------------------------
  */
 const EditLink = (props) => {
	const flowData = useSelector(state => state['Config']?.staticData?.flow?.data);
	 const eventData = useSelector(state => state['Config']?.eventConfig?.data);
	 const tempUserData = useSelector(state => state['UserData']?.tempUserData);
	 const Config = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
	
	 logger.log("[AccountInfo EditLink] props:%o", props); 
	 logger.log("[AccountInfo EditLink] cmsMessages:%o", Config);
	 logger.log('[AccountInfo EditLink] eventData:%o', eventData);
	 logger.log('[AccountInfo EditLink] flowData:%o', flowData);
 

	 //let text = "Edit Your Information";
	 let text = props.editable ? Config?.['account.edit_info_link'] : Config?.['account.edit_phone_email_link'];

	 logger.log("[AccountInfo EditLink] editable:%o, text:%o", props.editable, text);

	 if (props.category !== 'verify' && props?.editable !== undefined) {
		return (
      		<>
        		<EventsLink to={flowData?.actions?.general?.accountUpdate}>{text}</EventsLink>
				{
				(Config['system.regulatory_delete'] == 'true' && eventData.eventName !== 'psb') ? (
					<>
					<div className='separator'></div>
					<EventsLink to={flowData?.actions?.deleteAccount?.deleteaccount}> {Config['account.delete_prompt_title']} </EventsLink>
					</>
				) : null
				}
      		</>
		)
	 } else {
		 return <></>
	 }
 }
 const AccountInfo = (props) => {
	 logger.log('[AccountInfo] props:%o', props);
 
	 const countryList = useSelector(state => state['Config']?.staticData?.countries?.data?.countries);
	 const pendingEmail = useSelector(state => state['UserData']?.pendingEmail);
 
	 const countryName = countryList?.find(country => {
		 return country.value == props.userData.country;
	 })?.label;
 
	 const shippingCountryName = countryList?.find(country => {
		 return country.value == props.userData.shippingCountry;
	 })?.label;

	 const Config = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
	 const ticketsData = useSelector(state => state?.['Config']?.ticketsData);

	 logger.log('[AccountInfo] ticketsData:%o',ticketsData);
	 logger.log('[AccountInfo] pendingEmail:%o, countryName:%o, shippingCountryName:%o',pendingEmail, countryName, shippingCountryName);
 
	 if(op.get(props, 'userData', false)) {
		 let reviewName = `${op.get(props, 'userData.prefix', false) ? props.userData.prefix : ''} ${op.get(props, 'userData.firstName', '')} ${op.get(props, 'userData.lastName', '')}${op.get(props, 'userData.suffix', false) ? ', '+props.userData.suffix : ''}`;
		 let reviewAddress = `${op.get(props, 'userData.address1', '')}${op.get(props, 'userData.address2', false) ? '<br/>'+props.userData.address2 : ''}${op.get(props, 'userData.suiteName', false) ? '<br/>'+props.userData.suiteName : ''}${op.get(props, 'userData.suiteNumber', false) ? ' '+props.userData.suiteNumber : ''}<br/>${op.get(props, 'userData.city', '')}, ${op.get(props, 'userData.stateProvince', '')} ${op.get(props, 'userData.zipPostalCode', '')}${op.get(props, 'userData.zipPlus4', false) ? '-'+props.userData.zipPlus4 : ''}<br/>${countryName ? countryName : op.get(props, 'userData.country', '')}`;
		 let shippingAddress = props?.userData?.shippingAddress1 !== '' &&  null !== props?.userData?.shippingAddress1  
				? `${op.get(props, 'userData.shippingAddress1', '')}${op.get(props, 'userData.shippingAddress2', false) ? '<br/>'+props.userData.shippingAddress2 : ''}${op.get(props, 'userData.shippingSuiteName', false) ? '<br/>'+props.userData.shippingSuiteName : ''}${op.get(props, 'userData.shippingSuiteNumber', false) ? ' '+props.userData.shippingSuiteNumber : ''}<br/>${op.get(props, 'userData.shippingCity', '')}, ${op.get(props, 'userData.shippingStateProvince', '')} ${op.get(props, 'userData.shippingZipPostalCode', '')}${op.get(props, 'userData.shippingZipPlus4', false) ? '-'+props.userData.shippingZipPlus4 : ''}<br/>${shippingCountryName ? shippingCountryName : op.get(props, 'userData.shippingCountry', '')}`
				: false;

		 let reviewData;
     	 let shippingAddressLabel = props.userData.eventName == 'psb' ? "Address of Record" : "Billing/Shipping Address";
		 switch(props.category) {
			 case 'payment':
				reviewData = {
					"Name": reviewName,
					"Email": op.get(props, 'userData.email', ''),
					...(pendingEmail && { "Pending Email": pendingEmail }), // allows you to add this only if pendingEmail exists
					"Mobile Phone": op.get(props, 'userData.phone', ''),
				};
				reviewData[`${shippingAddressLabel}`] = <ErrorBoundary message="Unable to render content."><JSXParser jsx={reviewAddress} /></ErrorBoundary>

				break;
			 case 'receipt': {
				reviewData = {
					 "Order ID":op.get(props, 'userData.application.orderId', ''),
					 "Name": reviewName,
					 "Email": op.get(props, 'userData.email', ''),
					 ...(pendingEmail && { "Pending Email": pendingEmail }),
					 "Mobile Phone": op.get(props, 'userData.phone', ''),
 
				 };
          		reviewData[`${shippingAddressLabel}`] = <ErrorBoundary message="Unable to render content."><JSXParser jsx={reviewAddress} /></ErrorBoundary>

				 break;
			 }
			 case 'verify': 
				 reviewData = reviewAddress;
				 break;
			 case 'addressDisabled': {
				 reviewData = {
					 "Name": reviewName,
					 "Email": op.get(props, 'userData.email', ''),
					 ...(pendingEmail && { "Pending Email": pendingEmail }),
				 }
		
          if (props.userData.eventName !== 'psb') {
            reviewData["Last 4 Digits SSN/SIN"] = op.get(props, 'userData.ssn4', false) ? props.userData.ssn4.replace(/\d/g, '*') : 'N/A';
            reviewData["Year of Birth"] = op.get(props, 'userData.birthYear', '');
           } 

           reviewData[`${shippingAddressLabel}`] = <ErrorBoundary message="Unable to render content."><JSXParser jsx={reviewAddress} /></ErrorBoundary>

				 break;
			 }
			 default:
				 reviewData = {
					 "Name": reviewName,
					 "Email": op.get(props, 'userData.email', ''),
					 ...(pendingEmail && { "Pending Email": pendingEmail }),
					 "Mobile Phone": op.get(props, 'userData.phone', ''),
				 };
				 if (props.userData.eventName !== 'psb') {
					 reviewData["Last 4 Digits SSN/SIN"] = op.get(props, 'userData.ssn4', false) ? props.userData.ssn4.replace(/\d/g, '*') : 'N/A';
					 reviewData["Year of Birth"] = op.get(props, 'userData.birthYear', '');
          } 

         reviewData[`${shippingAddressLabel}`] = <ErrorBoundary message="Unable to render content."><JSXParser jsx={reviewAddress} /></ErrorBoundary>

				 break;
		 }
 
		 logger.log('[AccountInfo] reviewData:%o', reviewData);
		 logger.log('[AccountInfo] addressValidationStatusId:%o', props.userData.addressValidationStatusId);
		 logger.log('[AccountInfo] allowAddressEdit:%o', props?.eventData?.allowAddressEdit);
 
 
		 return (
			 <div className="account-info">
				 { props.category !== 'payment' && props.category !== 'verify' && props.category !== 'addressDisabled' ?
					 <>
						 <div className="header">
							 <h3>Account Information</h3>
							 <div className="edit-account-info"><EditLink category={props.category} userData={props?.userData} eventData={props?.eventData} editable={props.editable} /></div>
						 </div>
					 </>
					 : null
				 }
 
				 { props.category == 'payment' && props.action == 'review' ?
					 <>
						 <div className="header">
							 <h3>Account Information</h3>
							 <div className="edit-account-info"><EditLink category={props.category} userData={props?.userData} eventData={props?.eventData} /></div>
						 </div>

             			{ props.editable && props?.userData?.addressValidationStatusId !== 12 && props?.eventData?.allowPayment ? (
              					<CmsMessageContent id="payment.review_cc_instructions" />
             				) : (
								props.userData.eventName == 'psb' && props.eventData?.allowPayment ? (
									<>
									{props?.userData?.addressValidationStatusId !== 12 && props.userData.eventName == 'psb' 
										? (
											<CmsMessageContent id="payment.review_cc_instructions_editoff" />
										) 
										: <CmsMessageContent id="payment.review_cc_instructions_12" /> }
									</>
								) : null
									
							)}  
					 </>
					 : null
				 }

				{ (props.category == 'payment' && props.action !== 'review') ?
					 <>
						 <div className="header">
							 <h3>Order Summary</h3>
						 </div>
					 </>
					 : null
				 }
				 {
					 props.category !== 'verify' ? (
						 <table>
							 <tbody>
								 {
									 Object.keys(reviewData).map((key, i) => {
										 return (
											 <tr key={i}>
												 <td>{key}</td>
												 <td>{reviewData[key]}</td>
											 </tr>
										 )
									 })
								 }
							 </tbody>
						 </table>
					 ) : (
						 <ErrorBoundary message="Unable to render content."><JSXParser jsx={reviewData}></JSXParser></ErrorBoundary>
					 )
				 }
			 </div>
		 );
	 } else {
		 return null
	 }
 }
		 
 export default AccountInfo; EditLink; 