/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import MastersImage from 'shared/general/MastersImage';

/**
 * -----------------------------------------------------------------------------
 * React Component: Hero
 * -----------------------------------------------------------------------------
 */

const ContentHero = props => {
	const data = {
		heroType: 'quarter-height',
			imageConfig:{
				image: {
					alt: 'Course Image',
				},
				imageList: {
					small: '/assets/images/headers/header_patron_sm.jpg',
					medium: '/assets/images/headers/header_patron_qtr.jpg',
					high: '/assets/images/headers/header_patron_qtr.jpg',
					default: '/assets/images/headers/header_patron_qtr.jpg',
				},
			...props
		}		
	}
	return (
		<section
			className={`hero-section content-page ${
				data.heroType ? data.heroType : ''
			} ${data.className ? data.className : ''}`}>
			<MastersImage imageConfig={data.imageConfig} />
		</section>
	);
}
export default ContentHero;
