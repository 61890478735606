import deps from 'dependencies';
let parseString = require('xml2js').parseString;
import { validateData } from 'components/general/Util';
import uniq from 'lodash/uniq';
import MeasurementUtils from 'appdir/lib/analytics';
import { fetch } from 'shared/general/Util';

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentInfo] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	logger.log('[ContentInfo] getComponentListArray - ', { list, comps });
	return comps;
};

const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[ContentInfo]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[ContentInfo]  getAttributesArray - list: %o:', list);

	return attr;
};

export default {
	load: (path, zone = 'jsx-content-page', contentType, contentId=null, pageTitle=null) => dispatch => {
		logger.log('[ContentInfo] - load contentType:%o, id:%o', contentType, contentId);

		return fetch(path).then(content => {
			// logger.log('[ContentInfo] - componentDidUpdate content:%o', content);

			//set the xml parse options
			let options = {
				explicitArray: false,
				normalize: true,
				trim: true,
				mergeAttrs: true,
				valueProcessors: [],
			};

			//parse the xml
			parseString(content, options, function(err, json) {
				content = json.contentItem;
			});

			//clean the jsx block
			content.jsx = content.jsx
				.replace(/data="{(.*?)}"/gi, 'data={$1}')
				.replace(/class=/gi, 'className=');

			content.bindings = getAttributesArray(content.dependencies.data);

			getComponentListArray(content.dependencies.data).forEach(
				component =>
					dispatch(
						deps.actions.Plugable.addPlugin({
							id: `${component}-component`,
							component,
							zone,
							paths: ['cms/', 'common-ui/', 'data/'],
						})
					)
			);

			if (!window.webview) {
				content.contentId = contentId;
				MeasurementUtils.dispatchMeasurementCall(
					MeasurementUtils.ACTION_TYPES.pageView,
						{
							content,
							category: contentType,
							...(pageTitle) && {pageTitle},
						}
					);
				}
				dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: content });
			})
			.catch(error => {
				// add the error to redux. will ultimatly show "not found" if there's a problem
				dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: {error} });
			});		
	},
};
